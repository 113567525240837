import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useContext } from "react"
import { NavButton } from "../../../components/buttons/Buttons"
import { ApplicationContext } from "../../contexts/AppContext"
import { StatusContext } from "../../contexts/StatusContext"
import { postApi } from "../../Toolbox"


export const PortalNav = ({ page, action }) => {
    const [applicationState, setApplicationState] = useContext(ApplicationContext)
    const [statusState, statusDispatch] = useContext(StatusContext)

    const uploadAvatar = async () => {
        let data = new FormData()
        let el = document.getElementById("avatar-uploader")
        data.append("avatar", el.files[0]);
        const response = await postApi("users/avatar", data);
        if (response.status === 200) setApplicationState(ps => ({ ...ps, avatar: response.data.avatar }))
        response.message && statusDispatch({ level: response.level, message: response.message, timeout: 2000 })
    }

    const getAvatarFile = () => document.getElementById("avatar-uploader").click()

    const buildAvatar = () => {
        if (applicationState.avatar.mime && applicationState.avatar.stream)
            return (<img style={{ pointerEvents: "none" }} className="portal-nav-avatar-image" src={`data:${applicationState.avatar.mime};base64,${applicationState.avatar.stream}`} />)
        else
            return (<span className="portal-nav-avatar-no-image">{applicationState.initials}</span>)
    }

    return (
        <div className="portal-nav">
            <input type="file" id="avatar-uploader" style={{ visibility: "hidden", width: "0px", height: "0px" }} onChange={uploadAvatar} />
            <div className="portal-nav-avatar-container" onClick={getAvatarFile}>{buildAvatar()}</div>
            <div className="portal-nav-avatar-text">{applicationState.username}</div>
            <div className="portal-nav-avatar-text">{applicationState.practicename}</div>
            <hr />
            <NavButton selected={page == 1} action={() => action(1)} icon='envelopes-bulk'>Messaging</NavButton>
            <NavButton selected={page == 2} action={() => action(2)} icon='file-invoice'>Payment Ledger</NavButton>
            <NavButton selected={page == 3} action={() => action(3)} icon='shuffle'>File Transfers</NavButton>
            {(applicationState.master) ? <NavButton selected={page == 4} action={() => action(4)} icon="key">Password Manager</NavButton> : <></>}
            {(!applicationState.master && applicationState.admin) ? <NavButton selected={page == 5} action={() => action(5)} icon='cogs'>Settings</NavButton> : <></>}
            {(applicationState.master && applicationState.admin) ? <NavButton selected={page == 6} action={() => action(6)} icon="cog">Administration</NavButton> : <></>}
        </div>
    )
}