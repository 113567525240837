import { createContext, useReducer, useState } from "react";

export const MsgContext = createContext()

const initState = {
    folders: {data:[],selected:"",position:-1},
    messages: {data:[],selected:""},
    selected: []
}

const reducer = (state,action) => {
    switch(action.type){
        case "add_folders": {        
            return({
                ...state,
                folders:{...state.folders,data:action.payload}
            
            })}
        case "set_folder_select":{ 
            const  rec = state.folders.data.find(r=>r.id===action.payload)            
            return({
                ...state,
                folders:{...state.folders,selected:action.payload,position:rec ? rec.position : -1}                
            })
        }
        case "set_folder_position": return({
            folders:{...state.folders,position:action.payload}
        })
        case "add_messages": return({
            ...state,
            messages:{...state.messages,data:action.payload}
        })
        case "set_message_select": return({
            ...state,
            messages:{...state.messages,selected:action.payload}
        })
        case "set_selected": return({
            ...state,
            selected: action.payload
        })            
    }
}

export const MsgContextProvider = ({children}) => {
    const [state,dispatch] = useReducer(reducer,initState)
    const contextValue = {
        folders: state.folders,
        messages: state.messages,
        selected: state.selected,
        addFolders: (data) => dispatch({type:"add_folders",payload:data}),
        setFolderSelect: (data) => dispatch({type:"set_folder_select",payload:data}),
        setFolderPosition: (data) => dispatch({type:"set_folder_position",payload:data}),
        addMessages: (data) => dispatch({type:"add_messages",payload:data}),
        setMessageSelect: (data) => dispatch({type:"set_message_select",payload:data}),
        setSelected: (data) => dispatch({type:"set_selected",payload:data}),
    }
    return(<MsgContext.Provider value={contextValue}>{children}</MsgContext.Provider>)
}