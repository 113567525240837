import { useContext, useState } from "react";
import { Tabs } from "../../../components/tabs/Tabs";
import { ApplicationContext } from "../../contexts/AppContext";
import { UserGrid } from "../administration/UserGrid";
import { ProviderGrid } from "../administration/ProviderGrid";
import { LedTypeGrid } from "./LedTypeGrid";
import { Card, CardContent, CardHeader } from "../../../components/cards/Cards";

export const Settings = () => {
    const tabs = ["Ledger Settings", "Portal Users / Providers"]
    const [tab, setTab] = useState(0)
    const [applicationState, setApplicationState] = useContext(ApplicationContext)

    return (
        <div className="settings-wrapper">
            <div className="settings-tab-container">
                <Tabs items={tabs} dispatch={setTab}></Tabs>
            </div>
            <div className="settings-main-container">
                <Card style={{ width: "100%", flex: 1 }} nomodal>
                    <CardHeader>Practice Settings</CardHeader>
                    <CardContent>
                        <div className="settings-container">
                            {tab === 0 &&
                                <>
                                    <div className="payfor-container" ><LedTypeGrid type={0} /></div>
                                    <div className="paymethod-container"><LedTypeGrid type={1} /></div>
                                    <div className="paysource-container"><LedTypeGrid type={2} /></div>
                                </>
                            }
                            {tab === 1 &&
                                <>
                                    <div className="settings-user-container"><UserGrid parent={applicationState.practiceid} /></div>
                                    <div className="settings-provider-container"><ProviderGrid parent={applicationState.practiceid} /></div>
                                </>
                            }
                        </div>
                    </CardContent>
                </Card>
            </div>
        </div >
    )
}