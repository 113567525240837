import { useEffect, useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { buildColHeader, buildRows, GridContent, GridPaging, initGridState, setGridSelect } from "../../../components/grid/Grid"
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { GridActionButton } from "../../../components/buttons/Buttons";
import { UserForm } from "./UserForm";
import { useRestApi } from "../../hooks/UseRestApi";
import { CardToolBar, CardToolBarTitle } from "../../../components/cards/Cards";


export const UserGrid = ({ parent }) => {
    const [gridState, setGridState] = useState(initGridState("users-grid"))
    const gridData = useRestApi(gridState.url)
    const [editor, setEditor] = useState({ open: false, record: {}, index: -1 })    
    const gridColumns = [{
        columnId: "lastname",
        hdrText: "Last Name",
        width: "18%",
        render: (ndx) => gridData.data[ndx].lastname
    }, {
        columnId: "firstname",
        hdrText: "First Name",
        width: "18%",
        render: (ndx) => gridData.data[ndx].firstname
    }, {
        columnId: "emailaddress",
        hdrText: "Email Address",
        width: "40%",
        render: (ndx) => gridData.data[ndx].emailaddress
    }, {
        columnId: "lastlogin",
        hdrText: "Last Login",
        width: "20%",
        hdrStyle:{textAlign:"center"},
        cellStyle:{textAlign:"right"},
        render: (ndx) => gridData.data[ndx].lastlogin
    }, {
        columnId: "actions",
        hdrTextheader: "",
        width: "4%",
        type: "action",
        cellStyle: { textAlign: "center",borderRight:"none" },
        render: (ndx) => (
            <div className="inner pointer" data-id={gridData.data[ndx].id} onClick={callEditor}>
                <FontAwesomeIcon icon={faPencil} color="#e7b05e" style={{ pointerEvents: "none" }} />
            </div>
        )
    }]

    const callEditor = (e) => {
        let id = e.target.getAttribute("data-id")
        let ndx = gridData.data.findIndex(r => r.id === id)
        setEditor({ open: true, record: ndx > -1 ? gridData.data[ndx] : {}, index: ndx })
    }

    const editorCallBack = (resp) => {
        setEditor({ open: false, record: {}, index: -1 })
        let selected = resp.status === "success" ? resp.data.id : ""
        setGridState(ps => ({ ...ps, loader: !gridState.loader, selected: selected }))
    }

    const updatePage = (page) => {
        setGridState(ps => ({ ...ps, page: page, loader: !gridState.loader }))
    }

    const updateSelected = (e) => {
        setGridState(ps => ({ ...ps, selected: e.target.getAttribute("data-id") }))
    }

    useEffect(() => {
        let selected = setGridSelect("id", gridData.data, gridState.selected, editor.index)
        setGridState(ps => ({ ...ps, busy: false, selected: selected }))
    }, [gridData])

    useEffect(() => {    
        let newUrl = `users?parent=${parent}&page=${gridState.page}&limit=${gridState.limit}&loader=${gridState.loader}`
        setGridState(ps => ({ ...ps, url: newUrl, busy: true }))
    }, [parent, gridState.loader])

    return (<>
        <CardToolBar>
            <CardToolBarTitle>Practice Users</CardToolBarTitle>
            <div style={{ flex: 1, textAlign: "right" }}>
                <GridActionButton action={callEditor}>
                    <FontAwesomeIcon icon="plus" style={{ marginRight: "5px" }} />
                    New Practice User
                </GridActionButton>
            </div>
        </CardToolBar>
        <GridContent busy={gridData.busy}>
            {buildColHeader(gridColumns)}
            {buildRows(gridColumns, gridData.data, gridState.selected, updateSelected)}
        </GridContent>
        <GridPaging page={gridState.page} limit={gridState.limit} count={gridData.count} dispatch={updatePage} />
        {editor.open && <UserForm parent={parent} record={editor.record} callback={editorCallBack} />}
    </>)
}