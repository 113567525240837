import { CardButton, FormButton } from "../../../components/buttons/Buttons";
import { FormInput, InputField } from "../../../components/inputs/Inputs";

export const AccountIdFilter = (props) => {
    const newMouseLeft = `${(parseInt(props.mouseLeft.replace("px",""))-200)}px`

    const submitForm = () => props.dispatch("account",document.getElementById("account").value)    
    const reset = () => props.dispatch("account","" )
    
    return (
        <div className="card-modal">
            <div className="card-container bordertop" style={{ width: "200px", position: "absolute", top: props.mouseTop, left:newMouseLeft}}>
                <div style={{ marginBottom: "20px" }}></div>
                <div className="card-content form">
                    <InputField
                        id="account"
                        label="Account ID Contains"                        
                        hideerror
                        inputAttrib={{
                            defaultValue: props.account,
                            autoFocus: true,
                            onFocus: (e)=>e.target.select() 
                        }}
                        
                    />
                </div>
                <div className="card-footer form">
                    <div><CardButton small action={reset}>Reset</CardButton></div>
                    <div style={{ flex: 1, textAlign: "right" }}>
                        <CardButton small action={submitForm}>Filter</CardButton>
                    </div>
                    <CardButton small action={() => props.dispatch("cancel", "")} style={{marginLeft:"5px"}}>Cancel</CardButton>
                </div>
            </div>
        </div >
    )
}