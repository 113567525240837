//busy
//title
//deactivate
//save
//cancel

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { CardButton, LinkButton } from "../buttons/Buttons";
import { Card, CardFooter, CardHeader } from "../cards/Cards";
import { Config } from "../../application/Config";
import { useContext, useEffect, useState } from 'react';
import { StatusContext } from '../../application/contexts/StatusContext';

const FormCardHeader = ({ title, busy }) => {
    return (
        <CardHeader>
            <div className="card-header-text">{title}</div>
            <div className="card-header-icon">{busy ? <FontAwesomeIcon icon={faSpinner} spin /> : <></>}</div>
        </CardHeader>
    )
}

const FormCardContent = (props) => {
    return <div className="form-card-content">{props.children}</div>
}

const FormCardFooter = (props) => {
    return (
        <CardFooter>
            <div className="form-card-footer left">
                {props.deactivate ? <LinkButton action={() => props.action('deactivate')}>
                    <FontAwesomeIcon icon={faTrashAlt} style={{ marginRight: "3px" }}></FontAwesomeIcon>
                    Deactivate
                </LinkButton> : <></>}
            </div>
            <div className="form-card-footer button">
                {props.save ? <CardButton action={() => props.action('save')}>Save</CardButton> : <></>}
            </div>
            <div className="form-card-footer button">
                {props.cancel ? <CardButton action={() => props.action('cancel')}>Cancel</CardButton> : <></>}
            </div>
        </CardFooter>
    )
}

export const setFieldValues = (formId, record) => {
    if (formId) {
        let formFields = document.getElementById(formId)
        if (formFields) {
            [...formFields.querySelectorAll("[id]")].forEach(field => {
                if (field.type === "checkbox")
                    field.checked = record[field.id] || false;
                else
                    field.value = record[field.id] || "";
            })
        }
    }
}

export const initFormState = (id) => ({
    id: id,
    url: "",
    verb: "",
    busy: false,
    errors: {},
    loader: false
})

export const getFieldValues = (formId) => {
    let data = new FormData
    let formFields = [...document.getElementById(formId).querySelectorAll("[id]")]    
    formFields.forEach(field => {         
        data.append(field.id, field.type === "checkbox" ? field.checked : field.value) 
    })
    return (data)
}

export const useFormSubmit = (id, url, verb) => {
    const [result, setResult] = useState({ status: 0, message: "", errors: {}, count: 0, total: 0 })
    const [statusState, statusDispatch] = useContext(StatusContext)
    useEffect(() => {
        if (url) {
            const data = getFieldValues(id)
            let api = new URL(`${Config.apiUrl}/${url}`)
            fetch(api, { method: verb, credentials: "include", body: data })
                .then(resp => resp.json())
                .then(data => {
                    setResult({ status: data.status, data:data.data,message: data.message || "", errors: data.errors || {}, count: data.count || 0, total: data.total || 0 })
                    if (data.message) statusDispatch({ level: data.status, message: data.message, timeout: 2000 })
                })
        }
    }, [url])
    return result
}

export const FormCard = (props) => {
    return (
        <Card id={props.id}>
            <FormCardHeader {...props} />
            <FormCardContent {...props} />
            <FormCardFooter {...props} />
        </Card>
    )
}