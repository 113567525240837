import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react"
import { CardToolBar, CardToolBarTitle } from "../../../components/cards/Cards";
import { GridContent, GridHeader, GridPaging, initGridState, setGridSelect, useGetGridData, GridCell, buildRows, GridLoader, buildColHeader } from "../../../components/grid/Grid"
import { ApplicationContext } from "../../contexts/AppContext";
import { YesNoContext } from "../../contexts/YesNoContext";
import { useMousePosition } from "../../hooks/UseMousePosition";
import { deleteApi, download_file } from "../../Toolbox";


export const FileGrid = ({ reset,dispatchReset }) => {
    let delId = ""
    const mousePos = useMousePosition()
    const [gridState, setGridState] = useState(initGridState("users-grid", 10))
    const gridData = useGetGridData(gridState.id, gridState.url)
    const [editor, setEditor] = useState({ open: false, record: {}, index: -1 })
    const [applicationState, setApplicationState] = useContext(ApplicationContext)
    const [yesNoState, yesNoDispatch] = useContext(YesNoContext)

    const gridColumns = [{
        columnid: "filerow",
        width: "100%",
        cellStyle: { height: "70px" },        
        render: (ndx) => (<>
            <div className="files-icon">
                <img src={`data:image/jpeg;base64,${gridData.data[ndx].icon}`} alt="File Icon" height="60px" />
            </div>
            <div className="files-name">
                <h2>{gridData.data[ndx].filename}</h2>
                <span>{gridData.data[ndx].uploadedby}</span>
            </div>
            <div className="files-status">
                <div className="files-status-left">File Date:</div>
                <div className="files-status-right">{gridData.data[ndx].filedate}</div>
                <div className="files-status-left">File Time:</div>
                <div className="files-status-right">{gridData.data[ndx].filetime}</div>
                <div className="files-status-left">File Size:</div>
                <div className="files-status-right">{gridData.data[ndx].filesize}</div>
            </div>
            <div className="files-action" data-id={gridData.data[ndx].id} onClick={downloadFile}>
                <FontAwesomeIcon icon="download" color="#339966" size="3x" style={{ pointerEvents: "none" }} />
            </div>
            <div className="files-action" data-id={gridData.data[ndx].id} onClick={confirmDelete}>
                <FontAwesomeIcon icon="trash" color="#ff4d4d" size="3x" style={{ pointerEvents: "none" }} />
            </div>
        </>)
    }]

    const updatePage = (page) => {
        setGridState(ps => ({ ...ps, page: page, loader: !gridState.loader }))
    }

    const updateSelected = ({ target }) => {
        setGridState(ps => ({ ...ps, selected: target.getAttribute("data-id") }))
    }

    const downloadFile = ({ target }) => {
        let id = target.getAttribute("data-id")        
        let rec = gridData.data.find(r => r.id === id)         
        download_file(`files/download?fileid=${id}`, rec.filename, rec.ext)
        dispatchReset();
    }

    const confirmDelete = ({ target }) => {
        delId = target.getAttribute("data-id")
        yesNoDispatch({ message: "Remove This File.  Are you Sure?", top: mousePos.y, left: mousePos.x, offset:"right", dispatch: deleteFile })
    }

    const deleteFile = async () => {
        let data = new FormData()
        data.append("id", delId)
        let resp = await deleteApi("files", data)
        delId = ""
        yesNoDispatch({ message: "", top: "", left: "", dispatch: undefined })
        setGridState(ps => ({ ...ps, loader: !gridState.loader }))
        dispatchReset();
    }

    useEffect(() => {
        let selected = setGridSelect("id", gridData.data, gridState.selected, editor.index)
        setGridState(ps => ({ ...ps, busy: false, selected: selected }))
    }, [gridData])

    useEffect(() => {        
        let newUrl = `files?parent=${applicationState.practiceid}&page=${gridState.page}&limit=${gridState.limit}&reset=${reset}&loader=${gridState.loader}`
        setGridState(ps => ({ ...ps, url: newUrl, busy: true }))
    }, [reset,applicationState.practiceid, gridState.loader])

    return (<>
        <CardToolBar>
            <CardToolBarTitle>Download Your Files From Here</CardToolBarTitle>
        </CardToolBar>
        <GridContent busy={gridData.busy}>            
            {buildRows(gridColumns, gridData.data, gridState.selected, updateSelected,{height:"74px"})}
        </GridContent>
        <GridPaging page={gridState.page} limit={gridState.limit} count={gridData.count} dispatch={updatePage} />
    </>)
}