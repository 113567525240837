import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ForgotPwdForm } from "./classes/login/ForgotPwdForm";
import { LoginForm } from "./classes/login/LoginForm";
import { ResetPwdForm } from "./classes/login/ResetPwdForm";
import { Portal } from "./classes/portal/Portal";
import { ApplicationProvider } from "./contexts/AppContext";
import { StatusContextProvider } from "./contexts/StatusContext";
import { YesNoContextProvider } from "./contexts/YesNoContext";

import "./Icons";

export const App = () => {
  return (    
    <StatusContextProvider>
      <a id="downloader" style={{display:"hidden"}}></a>
      <YesNoContextProvider>
        <ApplicationProvider>
          <BrowserRouter>
            <Routes>
              <Route exact path="/login" element={<LoginForm />} />
              <Route exact path="/login/forgotpwd" element={<ForgotPwdForm />} />
              <Route exact path="/login/resetpwd/:id" element={<ResetPwdForm />} />
              <Route exact path="/portal" element={<Portal />} />
            </Routes>
          </BrowserRouter>
        </ApplicationProvider>
      </YesNoContextProvider>
    </StatusContextProvider>
  )
}