//nomodal
//nodim
//cardStyle
//headerStyle

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export const Card = (props) => {    
    if (props.nomodal){
        return(<div className="card-container" style={props.style || {}} id={props.id || ""}>{props.children}</div>)
    } else{
        return(
            <div className={`application-modal${props.nodim ? " nodim" : ""}`}>
                <div className="card-container" style={props.style || {}} id={props.id || ""}>{props.children}</div>                
            </div>
        )
    }
}

export const CardToolBar = (props) => {
    return <div className="card-toolbar" style={props.style || {} }>{props.children}</div>
}

export const CardToolBarTitle = (props) => {
    return <span className="card-toolbar-title" style={props.style || {} }>{props.children}</span>
}

export const CardHeader = (props) => {
    return <div className="card-header" style={props.headerStyle || {}}>{props.children}</div>
}

export const CardContent = (props) => {
    return <div className="card-content" {...props.cardAttrib}style={props.style || {}}>{props.children}</div>
}

export const CardFooter = (props) => {
    return <div className="card-footer" style={props.style || {} }>{props.children}</div>    
}

export const CardFormHeader = (props) => {
    return (
        <CardHeader>
            <div className="card-header-text">{props.title}</div>
            <div className="card-header-icon">{props.busy ? <FontAwesomeIcon icon={faSpinner} spin/> : <></>}</div> 
        </CardHeader>
    )
}
