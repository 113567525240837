import { useContext, useEffect, useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { buildColHeader, buildRows, GridContent, GridLoader, GridToolBar, initGridState } from "../../../components/grid/Grid"
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { GridActionButton, LinkButton } from "../../../components/buttons/Buttons";
import { useRestApi } from "../../hooks/UseRestApi";
import { setGridSelected } from "../../Toolbox";
import { useMousePosition } from "../../hooks/UseMousePosition"
import { QuestionForm } from "./QuestionForm";
import { PasswordContext } from "./PasswordContext";
import { CardToolBar, CardToolBarTitle } from "../../../components/cards/Cards";

export const QuestionGrid = () => {
    const mousePos = useMousePosition()
    const { webdata, secdata, addSecData, setSecDataSelect } = useContext(PasswordContext)
    const [gridState, setGridState] = useState(initGridState("question-grid"))
    const gridData = useRestApi(gridState.url)
    const [editor, setEditor] = useState({ open: false, record: {}, index: -1 })

    const gridColumns = [{
        columnId: "question",
        hdrText: "Security Question",
        width: "47%",
        render: (ndx) => secdata.data[ndx].question
    }, {
        columnId: "answer",
        hdrText: "Response",
        width: "47%",
        type: "action",
        render: (ndx) => {
            if (secdata.data[ndx].answer) {
                return (
                    <div className="copy-column" style={{ display: "flex", alignItems: "center" }}>
                        <div className="copy-column-text" data-id={secdata.data[ndx].id} onClick={updateSelected}>{secdata.data[ndx].answer}</div>
                        <div className="copy-column-icon" onClick={() => copyClipboard(secdata.data[ndx].answer)}>
                            <FontAwesomeIcon icon="copy" color="#435b70" style={{ pointerEvents: "none" }}></FontAwesomeIcon>
                        </div>
                    </div>
                )
            } else {
                <></>
            }
        }
    }, {
        columnId: "actions",
        hdrTextheader: "",
        width: "6%",
        type: "action",
        cellStyle: { textAlign: "center" },
        render: (ndx) => (
            <div className="inner pointer" data-id={secdata.data[ndx].id} onClick={callEditor}>
                <FontAwesomeIcon icon={faPencil} color="#e7b05e" style={{ pointerEvents: "none" }} />
            </div>
        )
    }]

    const callEditor = (e) => {
        let id = e.target.getAttribute("data-id")
        let ndx = gridData.data.findIndex(r => r.id === id)
        setEditor({ open: true, record: ndx > -1 ? secdata.data[ndx] : {}, index: ndx })
    }

    const editorCallBack = (resp) => {
        setEditor({ open: false, record: {}, index: -1 })
        let selected = resp.status === "success" ? resp.data.id : webdata.selected
        setSecDataSelect(selected)
        setGridState(ps => ({ ...ps, loader: !gridState.loader }))
    }

    const updateSelected = (e) => {
        let id = e.target.getAttribute("data-id")
        setSecDataSelect(id)
    }

    const copyClipboard = (val) => {
        navigator.clipboard.writeText(val)
        document.getElementById("copy-wrapper").classList.add("open")
        setTimeout(() => { document.getElementById("copy-wrapper").classList.remove("open") }, 1000);
    }

    useEffect(() => {
        let url = `security?parent=${webdata.selected}&page=${gridState.page}&limit=${gridState.limit}&loader=${gridState.loader}`
        setGridState(ps => ({ ...ps, url: url }))
    }, [webdata.selected, gridState.loader])


    useEffect(() => {
        let newSelect = setGridSelected("id", gridData.data, webdata.selected, editor.index)
        addSecData(gridData.data)
        setSecDataSelect(newSelect)
    }, [gridData])

    return (<>
        <CardToolBar>
            <CardToolBarTitle>Security QA</CardToolBarTitle>
            <div style={{ flex: 1, textAlign: "right" }}>
                <GridActionButton action={callEditor}>
                    <FontAwesomeIcon icon="plus" style={{ marginRight: "5px" }} />
                    New QA
                </GridActionButton>
            </div>
        </CardToolBar>
        <GridContent busy={gridData.busy}>
            {buildColHeader(gridColumns)}
            {buildRows(gridColumns, secdata.data, secdata.selected, updateSelected)}
        </GridContent>
        <div id="copy-wrapper" style={{ top: mousePos.y, left: mousePos.x }}>Copied...</div>
        {editor.open && <QuestionForm record={editor.record} callback={editorCallBack} />}
    </>)
}