import { useState } from "react"
import { FoldersGrid } from "./FolderGrid"
import { MessageGrid } from "./MessageGrid"
import { MsgContextProvider } from "../../contexts/MsgContext";
import { Preview } from "./Preview";
import { Card, CardContent, CardHeader } from "../../../components/cards/Cards";

export const Messaging = () => {
    const [resetGrids, setResetGrids] = useState(false)    
    const [msgParent, setMsgParent] = useState([])

    return (
        <MsgContextProvider>
            <div id="messaging-wrapper">
                <Card style={{ width: "100%", height: "100%" }} nomodal>
                    <CardHeader>Messaging Center</CardHeader>
                    <CardContent>
                        <div className="message-container">
                            <div className="message-container-left">
                                <FoldersGrid dispatchReset={() => setResetGrids(!resetGrids)} />
                            </div>
                            <div className="message-container-mid">
                                <MessageGrid 
                                    reset = {resetGrids} 
                                    dispatchReset={() => setResetGrids(!resetGrids)} 
                                    dispatchSelected = {(val)=> setMsgParent(val)}
                                />
                            </div>
                            <div className="message-container-right">
                                <Preview parent = {msgParent}/>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>
        </MsgContextProvider >













        // <div id="messaging-wrapper">
        //     <MsgContextProvider>
        //         <div className="folder-container"><FoldersGrid sendReset={gridReset} /></div>
        //         <div id="message-container"><MessageGrid reset={reset} /></div>
        //         <div className="preview-container"><Preview /></div> 
        //     </MsgContextProvider>
        // </div>
    )
}