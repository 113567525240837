import { useContext, useEffect, useRef, useState } from "react"
import { Card, CardHeader, CardToolBar, CardToolBarTitle } from "../../../components/cards/Cards"
import { GridContent } from "../../../components/grid/Grid"
import { MsgContext } from "../../contexts/MsgContext"

import NoMail from "../../assets/images/nomail.jpg"
import MultiMail from "../../assets/images/multimail.jpg"

export const Preview = () => {
    const { selected, messages } = useContext(MsgContext)
    const [record, setRecord] = useState()

    useEffect(() => {
        let rec = null;
        if (selected.length === 1) rec = messages.data.find(r => r.id === selected[0])
        setRecord(rec)
    }, [selected])

    const getDisList = () => {
        let disStr = []
        record.distlist.forEach(r => disStr.push(r.name));
        return disStr.toString()
    }
    
    return (<>
        <CardToolBar><CardToolBarTitle>Message Viewer</CardToolBarTitle></CardToolBar>
        {record &&
            <>
                <div className="preview-header">
                    <div className="preview-header-avatar">
                        {(record.avatar.mime && record.avatar.stream)
                            ? <img style={{ pointerEvents: "none" }} className="preview-avatar-image" src={`data:${record.avatar.mime};base64,${record.avatar.stream}`} />
                            : <span className="preview-avatar-no-image">{record.initials}</span>
                        }
                    </div>
                    <div className="preview-header-text">
                        <div className="preview-header-label">From:</div>
                        <div className="preview-header-data">{record.from.name}</div>
                        <div className="clearfix"></div>
                        <div className="preview-header-label">To:</div>
                        <div className="preview-header-data">{getDisList()}</div>
                        <div className="clearfix"></div>
                        <div className="preview-header-label">Date:</div>
                        <div className="preview-header-data">{record.msgdate}</div>
                        <div className="clearfix"></div>
                        <div className="preview-header-label">Subject:</div>
                        <div className="preview-header-data">{record.subject}</div>
                    </div>
                </div>
                {(selected.length == 1) &&
                    <GridContent>
                        <div id="preview-text" dangerouslySetInnerHTML={{ __html: record.msgtext }} ></div>
                    </GridContent>
                }
            </>
        }
        {(selected.length ===0) && 
           <GridContent>
                <div className="preview-no-mail"><img src={NoMail} alt="No Mail" height="50%" /></div>
            </GridContent>
        }
        {(!record && selected.length > 0) &&
            <GridContent>         
                <div className="preview-no-mail"><img src={MultiMail} alt="No Mail" height="50%" /></div>
            </GridContent>
        }             
    </>)
}