import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react"
import { CardButton, LinkButton } from "../../../components/buttons/Buttons";
import { Card, CardContent, CardFooter, CardHeader } from "../../../components/cards/Cards";
import { getFieldValues, initFormState, setFieldValues } from "../../../components/form/Form"
import { InputCurrency, InputField, InputTextArea } from "../../../components/inputs/Inputs";
import { PayForCombo } from "../../combos/PayForCombo";
import { PayMethodCombo } from "../../combos/PayMethodCombo";
import { PaySourceCombo } from "../../combos/PaySourceCombo";
import { YesNoContext } from "../../contexts/YesNoContext";
import { useMousePosition } from "../../hooks/UseMousePosition";
import { formApi } from "../../Toolbox";

export const LedgerForm = ({ parent, record, defaults, callback }) => {
    let mousePos = useMousePosition()
    const [formState, setFormState] = useState(initFormState("user-form"));
    const [yesNoState, yesNoDispatch] = useContext(YesNoContext)
    const [combos, setCombos] = useState({
        payfor: record.payfor ? [record.payfor.id] : [],
        paymethod: record.paymethod ? [record.paymethod.id] : [],
        paysource: record.paysource ? [record.paysource.id] : [],
    })
    
    const submitForm = async (e, deleteFlag = false) => {        
        setFormState(ps => ({ ...ps, busy: true }))
        let payload = getFieldValues(formState.id)        
        payload.append("id",record.id || "")
        payload.append("parent", parent)
        payload.append("payfor",combos.payfor[0])
        payload.append("paysource",combos.paysource[0])
        payload.append("paymethod",combos.paymethod[0])
        let resp = await formApi(record.id, "ledger", payload, deleteFlag)
        resp.status === "success" && callback({ status: "success", data: resp.data.id })
        resp.errors && setFormState(ps => ({ ...ps, errors: resp.errors, busy: false }))
    }

    const setComboValues = (id, val) => {
        let newId = id.replace("-combo","")        
        setCombos(ps => ({ ...ps, [newId]: val }))
    }

    const confirmDelete = (resp) => {
        yesNoDispatch({ message: "", top: "", left: "", dispatch: undefined })
        resp && submitForm(null, true)
    }

    const handleDelete = () => { yesNoDispatch({ message: "Deactivate This Record! Are you Sure?", left: mousePos.x, top: mousePos.y, dispatch: confirmDelete }) }

    useEffect(() => {
        const moneyFormat = new Intl.NumberFormat('en-US', { style: "currency", currency: "USD" });
        setFieldValues(formState.id, record);
        document.getElementById("ledgerdate").value = record.ledgerdate ? record.ledgerdate : defaults.ledgerDate
        document.getElementById("amount").value = record.amount ? moneyFormat.format(record.amount.replace(/[^\d.-]/g, '')) : "$0.00"
    }, [])

    return (
        <Card id={formState.id} style={{ width: "480px" }}>
            <CardHeader>
                <div className="card-header-text">Payment Ledger Editor</div>
                <div className="card-header-icon">{formState.busy ? <FontAwesomeIcon icon="fa-spinner" spin /> : <></>}</div>
            </CardHeader>
            <CardContent>
                <div className="form-row">
                    <div style={{ width: "130px", marginRight: "3px" }}>
                        <InputField id="ledgerdate" label="Ledger Date" inputAttrib={{ type: "date", style: { textAlign: "center" } }} />
                    </div>
                    <div style={{ flex: 1 }}></div>
                    <div style={{ width: "130px", marginRight: "3px" }}>
                        <InputCurrency
                            id="amount"
                            label="Payment Amount"
                            inputAttrib={{ autoFocus: true, style: { textAlign: "center" } }}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div style={{ width: "200px", marginRight: "3px" }}>
                        <PaySourceCombo
                            useDefault
                            label="Select The Payment Source"
                            parent={parent}
                            selected={combos.paysource}
                            dispatch={setComboValues}
                        />
                    </div>
                    <div style={{ flex: "1", marginRight: "3px" }}>
                        <InputField id="sourcename" label="Payer Name" />
                    </div>
                </div>
                <div className="form-row">
                    <div style={{ width: "200px", marginRight: "3px" }}>
                        <PayForCombo
                            useDefault
                            label="Select The Payment For"
                            parent={parent}
                            selected={combos.payfor}
                            dispatch={setComboValues}
                        />
                    </div>
                    <div style={{ flex: "1", marginRight: "3px" }}>
                        <InputField id="account" label="Account ID" />
                    </div>
                </div>
                <div className="form-row">
                    <div style={{ width: "200px", marginRight: "3px" }}>
                        <PayMethodCombo
                            useDefault
                            label="Select The Payment Method"
                            parent={parent}
                            selected={combos.paymethod}
                            dispatch={setComboValues}
                        />
                    </div>
                    <div style={{ flex: "1", marginRight: "3px" }}>
                        <InputField id="reference" label="Payment Reference" />
                    </div>
                </div>
                <div className="form-row">
                    <div style={{flex:1}}><InputTextArea hideError id="notes" label="Additional Information" inputStyle={{ height: "100px" }} /></div>
                </div>
            </CardContent>
            <CardFooter>
                <div className="form-card-footer left">
                    {record.id
                        ? <LinkButton action={handleDelete}><FontAwesomeIcon icon="trash" style={{ marginRight: "3px" }}></FontAwesomeIcon>Deactivate</LinkButton>
                        : <></>
                    }
                </div>
                <div className="form-card-footer button"><CardButton action={submitForm}>Save</CardButton></div>
                <div className="form-card-footer button"><CardButton action={() => callback({ status: "cancel" })}>Cancel</CardButton></div>
            </CardFooter>
        </Card>
    )
}