//===================================
// Props
//  id
//  label
//  error
//  labelPos
//  labelStyle
//  inputStyle
//  errorStyle
//  inputAttrib
//  hideError
//===================================

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faBan, faXmark } from '@fortawesome/free-solid-svg-icons';
import { IMaskInput } from "react-imask";
import { useEffect, useState } from 'react';

export const InputField = (props) => {
    let labelPosition = props.labelPos || "column"
    let flexCls = `input-wrapper ${labelPosition}`
    let errorCls = (props.error) ? "error" : ""
    return (<>
        <div className={flexCls}>
            {props.label ? <label htmlFor={props.id} className={errorCls} style={props.labelStyle || {}}>{props.label}</label> : <></>}
            <input
                id={props.id}
                name={props.id}
                className={errorCls}
                style={props.inputStyle || {}}
                disabled = {props.disabled}
                {...props.inputAttrib} />
        </div>
        {!props.hideError ? <div className="input-error">{props.error}</div> : <></>}
    </>)
}

export const InputHidden = (props) => {
    return <input id={props.id} className="hidden" value={props.holdValue} readOnly />
}


export const InputCurrency = (props) => {
    let labelPosition = props.labelPos || "column"
    let flexCls = `input-wrapper ${labelPosition}`
    let errorCls = (props.error) ? "error" : ""

    const handleBlur = ({ target }) => {
        let newNumber = "$0.00";
        if(target.value){
            newNumber = parseFloat(target.value.replace(/[^\d.-]/g, '')).toLocaleString('en-US', { style: "currency", currency: "USD" })
        }
        target.value = newNumber
    }
    const handleFocus = ({ target }) => target.select()

    const handleKeyDown = (e) => {
        let compareString = "$-,.1234567890TabBackspaceDelete"
        if (e.key === "-" && e.target.value.length > 0) e.preventDefault()
        !compareString.includes(e.key) && e.preventDefault()        
    }

    return (<>
        <div className={flexCls}>
            {props.label ? <label htmlFor={props.id} className={errorCls} style={props.labelStyle || {}}>{props.label}</label> : <></>}
            <input
                id={props.id}
                name={props.id}
                className={errorCls}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                onFocus={handleFocus}
                defaultValue="$0.00"                
                style={props.style || {}}
                {...props.inputAttrib}

            />
        </div>
        {!props.hideError ? <div className="input-error">{props.error}</div> : <></>}
    </>)

}






export const MaskedInputField = (props) => {
    let labelPosition = props.labelPos || "column"
    let flexCls = `input-wrapper ${labelPosition}`
    let errorCls = (props.error) ? "error" : ""
    const masks = [
        { name: "text", mask: "" },
        { name: "ssn", mask: "000-00-0000" },
        { name: "ein", mask: "00-0000000" },
        { name: "npi", mask: "0000000000" },
        { name: "phone", mask: "(000) 000-0000" },
        { name: "cc", mask: "0000 0000 0000 0000" },
        { name: "number", mask: Number },
        {
            name: "currency", mask: [
                { mask: "" },
                {
                    mask: '$num', lazy: false, blocks: {
                        num: {
                            mask: Number,
                            scale: 2,
                            thousandsSeparator: ',',
                            alwaysShowDecimal: true,
                            decimalLimit: 2,
                            padFractionalZeros: true,
                            normalizeZeros: true,
                            radix: '.',
                            min: -999999999999,
                            max: 999999999999
                        }
                    }
                }]
        }
    ]
    let inputmask = masks.find(r => r.name === props.mask) || ""

    return (<>
        <div className={flexCls}>
            {props.label ? <label htmlFor={props.id} className={errorCls} style={props.labelStyle || {}}>{props.label}</label> : <></>}
            <IMaskInput mask={inputmask.mask} id={props.id} name={props.id} {...props.inputAttrib} className={errorCls} style={props.inputStyle || {}} />
        </div>
        {!props.hideError ? <div className="input-error">{props.error}</div> : <></>}
    </>)
}

export const InputTextArea = (props) => {
    let labelPosition = props.labelPos || "column"
    let flexCls = `input-wrapper ${labelPosition}`
    let errorCls = (props.error) ? "error" : ""
    return (<>
        <div className={flexCls}>
            {props.label ? <label htmlFor={props.id} className={errorCls} style={props.labelStyle || {}}>{props.label}</label> : <></>}
            <textarea id={props.id} name={props.id} {...props.inputAttrib} className={errorCls} style={props.inputStyle || {}} />
        </div>
        {!props.hideError ? <div className="input-error">{props.error}</div> : <></>}
    </>)
}

export const InputSelect = (props) => {
    const [toggle, setToggle] = useState(false)
    const [selected, setSelected] = useState(props.selected)
    let labelPosition = props.labelPos || "column"
    let flexCls = `input-wrapper ${labelPosition}`
    let errorCls = (props.error) ? "error" : ""

    const toggleHandler = (e) => {
        if (e.target.id === props.id) {
            if (toggle) {
                setToggle(false)
            } else {

                if (props.items.length >= props.selected.length) setToggle(true)
            }
        } else {
            setToggle(false)
        }
    }

    useEffect(() => {
        window.addEventListener("click", toggleHandler);
        return () => window.removeEventListener("click", toggleHandler)
    }, [toggleHandler])

    useEffect(() => {
        setSelected(props.selected)
    }, [props.selected])

    const isSelected = (val) => (selected.findIndex(r => r === val) > -1)

    const selectItem = ({ target }) => {
        let id = target.getAttribute("data-id")
        let newSelected = [...selected]
        props.multi ? newSelected.push(id) : newSelected = [id]
        setSelected([...newSelected])
        props.setSelected(props.id, newSelected)
    }

    const removeItem = ({ target }) => {
        let id = target.getAttribute("data-id")
        let newSelected = [...selected]
        let ndx = newSelected.findIndex(r => r === id)
        if (ndx > -1) newSelected.splice(ndx, 1)
        setSelected([...newSelected])
        props.setSelected(props.id, newSelected)
    }

    const removeAllItems = () => {
        setSelected([])
        props.setSelected(props.id, [])
    }

    const buildItems = () => {
        return props.items.map((r, ndx) => {
            if (!isSelected(r.id)) {
                return (<div className='input-select-list-item' data-id={r.id} key={ndx} onClick={selectItem}>{r.value}</div>)
            }
        })
    }

    const buildSelected = () => {
        return selected.map((r, ndx) => {
            const rec = props.items.find(q => q.id === r)
            if (rec) {
                if (!props.multi) {
                    return <div className="input-selected-single" key={ndx}>{rec.value}</div>
                } else {
                    return (
                        <div className="input-selected-multi" key={ndx} data-id={rec.id} onClick={removeItem}>
                            <div className='text'>{rec.value}</div>
                            <div className='icon'><FontAwesomeIcon icon={faXmark} /></div>
                        </div>
                    )
                }
            }
        })
    }
    
    return (<>        
        <div className={flexCls}>
            {props.label ? <label htmlFor={props.id} className={errorCls} style={props.labelStyle || {}}>{props.label}</label> : <></>}
            <div className={`input-select-container${toggle ? " toggle" : ""}`} id={props.id}>
                <div className="selected">{buildSelected()}</div>
                {(props.multi && selected.length) ? <div className="delete" onClick={removeAllItems}><FontAwesomeIcon icon={faBan} /></div> : <></>}
                <div className="toggle">
                    <FontAwesomeIcon icon={faCaretLeft} className={toggle ? "toggle" : ""} />
                </div>
            </div>
            <div className='input-select-list-wrapper'>
                <div className={`input-select-list-container${toggle ? " toggle" : ""}`}>{buildItems()}</div>
            </div>
        </div>
        {!props.hideError ? <div className="input-error">{props.error}</div> : <></>}
    </>)
}

export const InputCheck = (props) => {
    return (
        <div className="input-check-wrapper">
            <label className="input-check-container" htmlFor={props.id}>{props.label}
                <input type="checkbox" id={props.id} name={props.id} {...props.inputAttrib} />
                <span className="checkmark"></span>
            </label>
        </div>
    )
}

export const InputSearchField = (props) => {
    let labelPosition = props.labelPos || "column"
    let flexCls = `input-wrapper ${labelPosition}`
    let errorCls = (props.error) ? "error" : ""
    return (<>
        <div className={flexCls}>
            {props.label ? <label htmlFor={props.id} className={errorCls} style={props.labelStyle || {}}>{props.label}</label> : <></>}
            <input id={props.id} name={props.id} type="search" {...props.inputAttrib} className={errorCls} style={props.inputStyle || {}} />
        </div>
        {!props.hideError ? <div className="input-error">{props.error}</div> : <></>}
    </>)
}