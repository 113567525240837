import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardButton } from "../../../components/buttons/Buttons";
import { Card, CardContent, CardFooter, CardHeader } from "../../../components/cards/Cards";

export const Logout = ({ dispatch }) => {
    return (
        <Card id="logout-form" style={{width: "300px"}}>
            <CardHeader><div className="card-header-text">Confirm Logout</div></CardHeader>
            <CardContent style={{padding: "20px"}}>
                <div style={{textAlign:"center",width: "100%"}}>Are you Sure You Wish To Logout?</div>
            </CardContent>
            <CardFooter style={{justifyContent:"center"}}>
                <div className="form-card-footer button"><CardButton style={{width: "50px"}} action={()=>dispatch(true)}>Yes</CardButton></div>
                <div className="form-card-footer button"><CardButton style={{width: "50px"}} action={()=>dispatch(false)}>No</CardButton></div>
            </CardFooter>
        </Card>
    )
}