import { useEffect, useState } from "react"
import { CardToolBar, CardToolBarTitle } from "../../../components/cards/Cards"
import { buildColHeader, buildRows, GridContent, GridPaging, initGridState, setGridSelect } from "../../../components/grid/Grid"
import { useRestApi } from "../../hooks/UseRestApi"

export const FileLogsGrid = ({ reset }) => {
    const [gridState, setGridState] = useState(initGridState("users-grid", 50))
    const gridData = useRestApi(gridState.url)
    const [editor, setEditor] = useState({ open: false, record: {}, index: -1 })    
    const gridColumns = [{
        columnId: "logdate",
        hdrText: "Log Date",
        width: "20%",
        render: (ndx) => gridData.data[ndx].logdate
    }, {
        columnId: "username",
        hdrText: "User Name",
        width: "20%",
        render: (ndx) => gridData.data[ndx].username
    }, {
        columnId: "filename",
        hdrText: "Filename",
        width: "45%",
        render: (ndx) => gridData.data[ndx].filename
    }, {
        columnId: "action",
        hdrText: "File Action",
        width: "15%",
        cellStyle: { borderRight: "none" },
        render: (ndx) => <span className={`files-action-${gridData.data[ndx].fileaction.toLowerCase()}`}>{gridData.data[ndx].fileaction}</span>
    }]

    const updatePage = (page) => {
        setGridState(ps => ({ ...ps, page: page, loader: !gridState.loader }))
    }

    const updateSelected = (e) => {
        setGridState(ps => ({ ...ps, selected: e.target.getAttribute("data-id") }))
    }

    useEffect(() => {
        let selected = setGridSelect("id", gridData.data, gridState.selected, editor.index)
        setGridState(ps => ({ ...ps, busy: false, selected: selected }))
    }, [gridData])

    useEffect(() => {        
        let newUrl = `fillogs?page=${gridState.page}&limit=${gridState.limit}&loader=${reset}`
        setGridState(ps => ({ ...ps, url: newUrl, busy: true, selected: "" }))
    }, [reset, gridState.loader])

    return (<>
        <CardToolBar>
            <CardToolBarTitle>File Transfer Logs</CardToolBarTitle>
        </CardToolBar>
        <GridContent busy={gridData.busy}>            
            {buildColHeader(gridColumns)}
            {buildRows(gridColumns, gridData.data, gridState.selected, updateSelected)}
        </GridContent>
        <GridPaging page={gridState.page} limit={gridState.limit} count={gridData.count} dispatch={updatePage} />
    </>)
}