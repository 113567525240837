import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { CardButton, LinkButton } from "../../../components/buttons/Buttons"
import { Card, CardContent, CardFooter, CardFormHeader } from "../../../components/cards/Cards"
import { initFormState, useFormSubmit } from "../../../components/form/Form"
import { InputField } from "../../../components/inputs/Inputs"

import Logo from "../../assets/images/nav-logo.png"

export const LoginForm = () => {    
    let nav = useNavigate()
    const [formState, setFormState] = useState(initFormState("login-form"))
    const formProcess = useFormSubmit(formState.id, formState.url, formState.verb)

    const submitForm = () => {
        let newUrl = `login?loader=${formState.loader}`
        setFormState(ps => ({ ...ps, url: newUrl, busy: true, verb: "POST", loader: !formState.loader }))
    }
    
    useEffect(() => {
        setFormState(ps=>({...ps,busy:false}))
         formProcess.status === "success" && nav("/portal", { replace: true })          
         formProcess.errors && setFormState(ps=>({...ps,errors:formProcess.errors}))
    }, [formProcess])


    return (
        <div className="login-container">
            <div className="login-container-upper"></div>
            <div className="login-container-lower"></div>
            <div className="application-modal" nodim="true">
                <Card id={formState.id} style={{ width: "380px" }}>
                    <CardFormHeader title="Please Sign In" busy={formState.busy} />
                    <CardContent style={{padding: "10px"}}>
                        <div className="login-form-container">
                            <div className="login-form-container-left">
                                <img src={Logo} height="160px" alt="PPS Logo" />
                            </div>
                            <div className="login-form-container-right">
                                <InputField id="userid" label="Sign In ID:" error={formState.errors.userid || ""} inputAttrib={{ autoFocus: true }} />
                                <InputField id="password" label="Enter Your Password:" inputAttrib={{ type: "password" }} error={formState.errors.password || ""} />
                                <div className="login-miranda">
                                    By logging into this account, You agree to our <LinkButton style={{ fontSize: ".70rem" }}>Terms</LinkButton> and have read and acknowledge our
                                    <div><LinkButton style={{ fontSize: ".70rem" }}>Global Privacy Statement</LinkButton></div>
                                </div>
                            </div>
                        </div>
                    </CardContent>
                    <CardFooter>
                        <div className="form-card-footer left">
                            <LinkButton action={() => nav("/login/forgotpwd", { replace: true })}>Forgot Password</LinkButton>
                        </div>
                        <div className="form-card-footer button">
                            <CardButton action={submitForm}>Login</CardButton>
                        </div>
                    </CardFooter>
                </Card>
            </div>
        </div>
    )
}