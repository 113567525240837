import { useContext, useEffect, useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { buildColHeader, buildRows, GridContent, GridLoader, GridPaging, GridToolBar, initGridState } from "../../../components/grid/Grid"
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { GridActionButton, LinkButton } from "../../../components/buttons/Buttons";
import { useRestApi } from "../../hooks/UseRestApi";
import { setGridSelected } from "../../Toolbox";
import { useMousePosition } from "../../hooks/UseMousePosition"
import { PracticeCombo } from "../../combos/PracticeCombo";
import { PasswordForm } from "./PasswordForm";
import { PasswordContext } from "./PasswordContext";
import { CardToolBar, CardToolBarTitle } from "../../../components/cards/Cards";


export const PasswordGrid = ({ dispatchReset }) => {
    const mousePos = useMousePosition()
    const { webdata, addWebData, setWebDataSelect } = useContext(PasswordContext)
    const [gridState, setGridState] = useState(initGridState("password-grid"))
    const gridData = useRestApi(gridState.url)
    const [parent, setParent] = useState([])
    const [editor, setEditor] = useState({ open: false, record: {}, index: -1 })

    const gridColumns = [{
        columnId: "portalname",
        hdrText: "Portal / Website",
        width: "28%",
        type: "action",
        render: (ndx) => {
            return (
                <div className="inner" data-id={webdata.data[ndx].id}>
                    {webdata.data[ndx].weburl
                        ? <a href={webdata.data[ndx].weburl} target="_blank">{webdata.data[ndx].pordata.value}</a>
                        : webdata.data[ndx].pordata.value
                    }
                </div>
            )
        }
    }, {
        columnId: "userid",
        hdrText: "User ID",
        width: "20%",
        type: "action",
        render: (ndx) => {
            if (webdata.data[ndx].username) {
                return (
                    <div className="copy-column" style={{ display: "flex", alignItems: "center" }}>
                        <div className="copy-column-text" data-id={webdata.data[ndx].id} onClick={updateSelected}>{webdata.data[ndx].username}</div>
                        <div className="copy-column-icon" onClick={() => copyClipboard(webdata.data[ndx].username)}>
                            <FontAwesomeIcon icon="copy" color="#435b70" style={{ pointerEvents: "none" }}></FontAwesomeIcon>
                        </div>
                    </div>
                )
            } else {
                <></>
            }
        }
    }, {
        columnId: "password",
        hdrText: "Password",
        width: "20%",
        type: "action",
        render: (ndx) => {
            if (webdata.data[ndx].password) {
                return (
                    <div className="copy-column" style={{ display: "flex", alignItems: "center" }}>
                        <div className="copy-column-text" data-id={webdata.data[ndx].id} onClick={updateSelected}>{webdata.data[ndx].password}</div>
                        <div className="copy-column-icon" onClick={() => copyClipboard(webdata.data[ndx].password)}>
                            <FontAwesomeIcon icon="copy" color="#435b70" style={{ pointerEvents: "none" }}></FontAwesomeIcon>
                        </div>
                    </div>
                )
            } else {
                <></>
            }
        }
    }, {
        columnId: "emailaddress",
        hdrText: "Associated Email",
        width: "28%",
        type: "action",
        render: (ndx) => {
            if (webdata.data[ndx].emailaddress) {
                return (
                    <div className="copy-column" style={{ display: "flex", alignItems: "center" }}>
                        <div className="copy-column-text" data-id={webdata.data[ndx].id} onClick={updateSelected}>{webdata.data[ndx].emailaddress}</div>
                        <div className="copy-column-icon" onClick={() => copyClipboard(webdata.data[ndx].emailaddress)}>
                            <FontAwesomeIcon icon="copy" color="#435b70" style={{ pointerEvents: "none" }}></FontAwesomeIcon>
                        </div>
                    </div>
                )
            } else {
                <></>
            }
        }
    }, {
        columnId: "actions",
        hdrTextheader: "",
        width: "4%",
        type: "action",
        cellStyle: { textAlign: "center" },
        render: (ndx) => (
            <div className="inner pointer" data-id={webdata.data[ndx].id} onClick={callEditor}>
                <FontAwesomeIcon icon={faPencil} color="#e7b05e" style={{ pointerEvents: "none" }} />
            </div>
        )
    }]

    const callEditor = (e) => {
        let id = e.target.getAttribute("data-id")
        let ndx = gridData.data.findIndex(r => r.id === id)
        setEditor({ open: true, record: ndx > -1 ? webdata.data[ndx] : {}, index: ndx })
    }

    const editorCallBack = (resp) => {
        setEditor({ open: false, record: {}, index: -1 })
        let selected = resp.status === "success" ? resp.data : ""
        setWebDataSelect(selected)
        setGridState(ps => ({ ...ps, loader: !gridState.loader }))
        dispatchReset()
    }

    const updatePage = (page) => {
        setGridState(ps => ({ ...ps, page: page, loader: !gridState.loader }))
    }

    const updateSelected = (e) => {
        let id = e.target.getAttribute("data-id")
        setWebDataSelect(id)
    }

    const copyClipboard = (val) => {
        navigator.clipboard.writeText(val)
        document.getElementById("copy-wrapper").classList.add("open")
        setTimeout(() => { document.getElementById("copy-wrapper").classList.remove("open") }, 1000);
    }

    useEffect(() => {
        let url = `passwords?parent=${parent}&page=${gridState.page}&limit=${gridState.limit}&reset=${gridState.loader}`
        setGridState(ps => ({ ...ps, url: url }))
    }, [parent, gridState.loader])

    useEffect(() => {
        let newSelect = setGridSelected("id", gridData.data, webdata.selected, editor.index)
        addWebData(gridData.data)
        setWebDataSelect(newSelect)
    }, [gridData])

    return (<>
        <CardToolBar>
            <CardToolBarTitle>Portal Password Profiles</CardToolBarTitle>
            <div style={{ flex: 1, display:"flex",alignItems:"center" }}>
                <div style={{ width: "120px"}}>Select Practice:</div>
                <div style={{ width: "300px",}}>
                    <PracticeCombo style={{textAlign:"left"}} id="practice-combo" dispatch={(id, val) => setParent(val)} />
                </div>
            </div>
            <div style={{ flex: 1, textAlign: "right" }}>
                <GridActionButton action={callEditor}>
                    <FontAwesomeIcon icon="plus" style={{ marginRight: "5px" }} />
                    New Practice
                </GridActionButton>
            </div>
        </CardToolBar>
        <GridContent busy={gridData.busy}>
            {buildColHeader(gridColumns)}
            {buildRows(gridColumns, webdata.data, webdata.selected, updateSelected)}
        </GridContent>
        <GridPaging page={gridState.page} limit={gridState.limit} count={gridData.count} dispatch={updatePage} />
        <div id="copy-wrapper" style={{ top: mousePos.y, left: mousePos.x }}>Copied...</div>
        {editor.open && <PasswordForm parent={parent} record={editor.record} callback={editorCallBack} />}
    </>)
}