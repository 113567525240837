//level
//message
//timeout

import { useContext, useRef, useState, useEffect } from "react";
import { createContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleExclamation, faCircleXmark, faXmark } from '@fortawesome/free-solid-svg-icons';


const StatusCard = () => {
    const [statusState, statusDispatch] = useContext(StatusContext)

    let statusRef = useRef(0)
    let icon = faCircleCheck;
    if (statusState.level == "info") icon = faCircleExclamation;
    if (statusState.level == "error") icon = faCircleXmark;

    const timeout = setTimeout(() => {
        statusRef.current.classList.add("open")
        setTimeout(() => {            
            setTimeout(() => {
                statusState.dispatch && statusState.dispatch();
                statusDispatch({ level: 0, message: "", timeout: 2000 })
            }, 200)
        }, statusState.timeout);
    }, 100)

    useEffect(() => { return () => clearTimeout(timeout); }, [])

    return (
        <div className="application-modal nodim">
            <div className={`status-card ${statusState.level}`} ref={statusRef}>
                <div style={{ position: "relative", width: "100%", height: "100%" }}>
                    <div className="status-card-close" onClick={() => statusDispatch({ level: 0, message: "", timeout: 2000 })}><FontAwesomeIcon icon={faXmark} /></div>
                    <div className={`status-card-icon ${statusState.level}`}><FontAwesomeIcon icon={icon} /></div>
                    <div className="status-card-text">{statusState.message}</div>
                </div>
            </div>
        </div>
    )
}

export const StatusContext = createContext()
export const StatusContextProvider = ({ children }) => {
    const [statusState, statusDispatch] = useState({ level: 0, message: "" })
    return (
        <StatusContext.Provider value={[statusState, statusDispatch]}>
            {children}
            {(statusState.message && statusState.level) ? <StatusCard /> : ""}
        </StatusContext.Provider>
    )
};