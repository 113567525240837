import { CardButton, FormButton } from "../../../components/buttons/Buttons";
import { InputField } from "../../../components/inputs/Inputs";



export const DateFilter = (props) => {
    const submitForm = () => {
        let start = document.getElementById("start-date").value
        let end = document.getElementById("end-date").value
        props.dispatch("ledgerdate",{ startDate: start, endDate:end })
    } 
    const reset = () => {
        let today = new Date().toJSON().split('T')[0]
        props.dispatch("ledgerdate",{startDate: today, endDate: today })
    }

    return (
        <div className="card-modal">
            <div className="card-container bordertop" style={{ width: "200px", position: "absolute", top: props.mouseTop,left: props.mouseLeft }}>
                <div style={{ marginBottom: "20px" }}></div>
                <div className="card-content form">
                    <InputField
                        id="start-date"
                        label="Starting Date"                                                
                        inputAttrib={{
                            type: "date",
                            defaultValue: props.startDate, 
                            onKeyDown: (e) => e.preventDefault(),
                        }}
                    />
                    <InputField
                        id="end-date"
                        label="Ending Date"                        
                        inputAttrib={{
                            type: "date",
                            defaultValue: props.endDate, 
                            onKeyDown: (e) => e.preventDefault(),
                        }}
                    />
                </div>
                <div className="card-footer form">
                    <div><CardButton small action={reset}>Reset</CardButton></div>
                    <div style={{ flex: 1, textAlign: "right" }}>
                        <CardButton small action={submitForm}>Filter</CardButton>
                    </div>
                    <CardButton small action={() => props.dispatch("cancel", "")} style={{marginLeft:"5px"}}>Cancel</CardButton>
                </div>
            </div>
        </div >
    )
}