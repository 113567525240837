import { CardButton } from "../../../components/buttons/Buttons";
import { InputField, MaskedInputField } from "../../../components/inputs/Inputs";

export const AmountFilter = (props) => {
    const newMouseLeft = `${(parseInt(props.mouseLeft.replace("px", "")) - 200)}px`

    const submitForm = () => {
        let data = {
            startAmount: parseFloat(document.getElementById("amountstart").value.replace("$", "").replace(",", "")),
            endAmount: parseFloat(document.getElementById("amountend").value.replace("$", "").replace(",", ""))
        }
        props.dispatch("amount", data)
    }

    const reset = () => {
        let data = { startAmount: 0, endAmount: 0 }
        props.dispatch("amount", data)
    }

    return (
        <div className="card-modal">
            <div className="card-container bordertop" style={{ width: "200px", position: "absolute", top: props.mouseTop, left: newMouseLeft }}>
                <div style={{ marginBottom: "20px" }}></div>
                <div className="card-content form">
                    <InputField
                        id="amountstart"
                        label="Start Amount"
                        inputAttrib={{
                            autoFocus: true,
                            defaultValue: props.startAmount,
                            style:{ textAlign: "right" },
                            onBlur: ({ target }) => target.value = `$${parseFloat(target.value).toFixed(2)}`,
                            onFocus: (e) => e.target.select()
                        }}
                    />
                    <InputField
                        id="amountend"
                        label="Ending Amount"
                        inputAttrib={{                            
                            defaultValue: props.endAmount,
                            style:{ textAlign: "right" },
                            onBlur: ({ target }) => target.value = `$${parseFloat(target.value).toFixed(2)}`,
                            onFocus: (e) => e.target.select()                            
                        }}
                    />
                </div>
                <div className="card-footer form">
                    <div><CardButton small action={reset}>Reset</CardButton></div>
                    <div style={{ flex: 1, textAlign: "right" }}>
                        <CardButton small action={submitForm}>Filter</CardButton>
                    </div>
                    <CardButton small action={() => props.dispatch("cancel", "")} style={{ marginLeft: "5px" }}>Cancel</CardButton>
                </div>
            </div>
        </div >
    )
}