import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useContext, useEffect, useState } from "react"
import { GridActionButton } from "../../../components/buttons/Buttons"
import { CardToolBar, CardToolBarTitle } from "../../../components/cards/Cards"
import { initGridState, GridContent, setGridSelect } from "../../../components/grid/Grid"
import { MsgContext } from "../../contexts/MsgContext"
import { StatusContext } from "../../contexts/StatusContext"
import { useRestApi } from "../../hooks/UseRestApi"
import { putApi } from "../../Toolbox"
import { FolderForm } from "./FolderForm"
import { MessageForm } from "./MessageForm"

export const FoldersGrid = ({ dispatchReset }) => {
    const {folders,addFolders,setFolderSelect,setSelected} = useContext(MsgContext)
    const [statusState, statusDispatch] = useContext(StatusContext)
    const [gridState, setGridState] = useState(initGridState("folder-grid"))
    const gridData = useRestApi(gridState.url)
    const [editor, setEditor] = useState({ open: false, record: {}, index: -1 })
    const [msgEditor, setMsgEditor] = useState({ open: false, record: {}, index: -1 })
    
    const icons = [
        { icon: "fa-envelope", color: "#595959" },
        { icon: "fa-paper-plane", color: "dodgerblue" },
        { icon: "fa-box-archive", color: "green" },
        { icon: "fa-trash", color: "orange" },
        { icon: "fa-flag", color: "#ff4d4d" },
        { icon: "fa-folder", color: "#0c5081" }
    ]

    const buildGridRows = (systemonly = false) => {
        return folders.data.map((r, rndx) => {
            if (systemonly && (!r.issystem)) return;
            if (!systemonly && (r.issystem)) return;
            let pos = r.position
            if (pos > 5) pos = 5;
            let ddStatus = !(r.position === 1 || r.position === 4 || r.id == folders.selected )
            return (
                <div
                    className={`folder-row${r.id === folders.selected ? " selected" : ""}`}
                    data-id={r.id} key={rndx}
                    onClick={updateSelected}
                    onDragEnter={ddStatus ? dragEnter : undefined}
                    onDragOver={ddStatus ? dragOver : undefined}
                    onDrop={ddStatus ? dragDrop : undefined}
                    onDragLeave={ddStatus ? dragLeave : undefined}
                >
                    <div className="folder-icon"><FontAwesomeIcon icon={icons[pos].icon} color={icons[pos].color} /></div>
                    <div className="folder-text">{r.foldername}</div>
                    {!r.issystem && <div className="folder-icon" style={{ cursor: "pointer", pointerEvents: "all" }} onClick={callEditor} data-id={r.id}>
                        <FontAwesomeIcon style={{ pointerEvents: "none" }} icon="fa-pencil" color="#FDB600" />
                    </div>}
                </div>
            )
        })
    }

    const updateSelected = (e) => {
        let id = e.target.getAttribute("data-id")        
        setFolderSelect(id)
    }

    const callEditor = (e) => {
        let id = e.target.getAttribute("data-id")
        let ndx = gridData.data.findIndex(r => r.id === id)
        setEditor({ open: true, record: ndx > -1 ? gridData.data[ndx] : {}, index: ndx })
    }

    const callMsgEditor = (e) => {
        setMsgEditor({ open: true, record: {} })
    }

    const editorCallBack = (resp) => {
        setEditor({ open: false, record: {}, index: -1 })        
        setFolderSelect(resp.status === "success" ? resp.data.id : folders.selected)        
        setGridState(ps => ({ ...ps, loader: !gridState.loader }))        
    }

    const msgEditorCallback = (resp) => {
        setMsgEditor({ open: false, record: {} })
        resp.status === "success" && statusDispatch({ level: "success", message: "Your Message Has Been Sent", timeout: 2000 })
        dispatchReset()
    }

    const dragEnter = (e) => {
        e.preventDefault()
        e.target.classList.add("draghover")
    }

    const dragLeave = (e) => {
        e.preventDefault()
        e.target.classList.remove("draghover")
    }

    const dragOver = (e) => {
        e.stopPropagation();
        e.preventDefault();
    }

    const dragDrop = async (e) => {
        e.preventDefault();
        e.target.classList.remove("draghover")
        let data = e.dataTransfer.getData("dragList")
        let folderid = e.target.getAttribute("data-id")
        let payload = new FormData()
        payload.append("folder", folderid)
        payload.append("drop", true)
        payload.append("messages", JSON.stringify(data))
        let resp = await putApi("messages", payload)
        dispatchReset()
        setSelected([])
    }

    useEffect(() => {            
        addFolders(!gridData.data.length ? [] : gridData.data)                 
        setFolderSelect(setGridSelect("id", gridData.data, gridState.selected, editor.index))                        
    }, [gridData])

    useEffect(() => {
        let newUrl = `folders?page=${gridState.page}&limit=${gridState.limit}&loader=${gridState.loader}`        
        setGridState(ps => ({ ...ps, url: newUrl}))
    }, [gridState.loader])

    return (<>
        <CardToolBar>
            <CardToolBarTitle>Mailboxes</CardToolBarTitle>
            <GridActionButton action={callMsgEditor} style={{ width: "116px" }}>
                <FontAwesomeIcon icon="plus" style={{ marginRight: "5px" }} />
                New Message
            </GridActionButton>
        </CardToolBar>
        <div className="mailbox-container">
            <GridContent busy={gridData.busy}>{buildGridRows(true)}</GridContent>
        </div>
        <CardToolBar>
            <CardToolBarTitle>Folders</CardToolBarTitle>
            <GridActionButton action={callEditor} style={{ width: "116px" }}>
                <FontAwesomeIcon icon="plus" style={{ marginRight: "5px" }} />
                New Folder
            </GridActionButton>
        </CardToolBar>
        <div className="folder-container">
            <GridContent busy={gridData.busy}>{buildGridRows(false)}</GridContent>
        </div>
        <GridContent busy={gridData.busy}>
            {buildGridRows(false)}
        </GridContent>
        {editor.open && <FolderForm record={editor.record} callback={editorCallBack} />}
        {msgEditor.open && <MessageForm type={0} record={msgEditor.record} callback={msgEditorCallback} />}
    </>)
}