import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useContext, useEffect, useState } from "react"
import { GridActionButton } from "../../../components/buttons/Buttons"
import { CardToolBar, CardToolBarTitle } from "../../../components/cards/Cards"
import { GridContent, buildColHeader, buildRows } from "../../../components/grid/Grid"
import { PracticeCombo } from "../../combos/PracticeCombo"
import { UserCombo } from "../../combos/UserCombo"

import { StatusContext } from "../../contexts/StatusContext"
import { putApi } from "../../Toolbox"


export const FileUploads = ({ dispatchReset }) => {
    const [comboBoxes, setComboBoxes] = useState({ practice: [], user: [] })
    const [fileList, setFileList] = useState({ data: [], selected: "", busy: false })
    const [statusState, statusDispatch] = useContext(StatusContext)

    const gridColumns = [{
        columnid: "filedate",
        hdrText: "File Date",
        width: "11%",
        hdrStyle: { textAlign: "center" },
        cellStyle: { textAlign: "right" },
        style: { pointerEvents: "none" },
        render: (ndx) => {
            let fd = new Date(fileList.data[ndx].lastModified);
            let month = fd.getMonth() + 1
            let newMonth = (month < 10) ? `0${month}` : month;
            let filedate = `${newMonth}/${fd.getDate()}/${fd.getFullYear()}`
            return filedate
        }
    }, {
        columnid: "filetime",
        hdrText: "File Time",
        width: "9%",
        hdrStyle: { textAlign: "center" },
        cellStyle: { textAlign: "right" },
        style: { pointerEvents: "none" },
        render: (ndx) => {
            let fd = new Date(fileList.data[ndx].lastModified);
            let hours = fd.getHours() > 12 ? fd.getHours() - 12 : fd.getHours()
            let minutes = fd.getMinutes() < 10 ? `0${fd.getMinutes()}` : fd.getMinutes()
            let ampm = fd.getHours() > 12 ? "PM" : "AM"
            let filetime = `${hours}:${minutes}${ampm}`
            return filetime
        }
    }, {
        columnid: "filename",
        hdrText: "File Name",
        width: "76%",
        style: { pointerEvents: "none" },
        render: (ndx) => fileList.data[ndx].name
    }, {
        columnid: "action",
        header: "",
        width: "4%",
        style: { pointerEvents: "none" },
        cellStyle: { borderRight: "none", textAlign: "center" },
        type: "action",
        render: (ndx) => (
            <div className="inner pointer" data-id={ndx} onClick={removeFile} >
                <FontAwesomeIcon icon="fa-ban" color="red" style={{ pointerEvents: "none" }} />
            </div>
        )
    }]

    const updateSelected = (id) => {
        setFileList(ps => ({ ...ps, selected: id }))
    }

    const dispatchSelected = (id, val) => {
        id === "practice-combo" && setComboBoxes(ps => ({ ...ps, practice: val }))
        id === "user-combo" && setComboBoxes(ps => ({ ...ps, user: val }))
    }

    const dragFiles = (e) => {
        if (e.target.id !== "files-drop-container") {
            e.preventDefault()
            e.dataTransfer.effectsallowed = 'none'
            e.dataTransfer.dropEffect = 'none'
        } else {
            e.preventDefault()
            let ctr = document.getElementById("files-drop-container")
            ctr && ctr.classList.add("dragover")
        }
    }

    const dragOut = () => {
        let ctr = document.getElementById("files-drop-container")
        ctr && ctr.classList.remove("dragover")
    }

    const dropFiles = (e) => {
        e.preventDefault();
        let ctr = document.getElementById("files-drop-container")
        ctr && ctr.classList.remove("dragover")
        let newList = [...fileList.data];
        [...e.dataTransfer.files].forEach(f => newList.push(f))
        setFileList(ps => ({ ...ps, data: [...newList] }));
    }

    const addFiles = (e) => {
        e.preventDefault();
        let newList = [...fileList.data];
        [...e.target.files].forEach(f => newList.push(f))
        setFileList(ps => ({ ...ps, data: [...newList] }));
    }

    const removeFile = (e) => {
        e.stopPropagation()
        e.preventDefault()
        let newList = [...fileList.data]
        let ndx = e.target.getAttribute("data-id")
        newList.splice(ndx, 1)
        setFileList(ps => ({ ...ps, data: [...newList] }));
    }

    const uploadFiles = async () => {
        setFileList(ps => ({ ...ps, busy: true }))
        let form = new FormData
        form.append("practiceid", comboBoxes.practice)
        form.append("userid", comboBoxes.user)
        fileList.data.forEach(file => form.append("files[]", file))
        let response = await putApi("files", form)
        setFileList({ data: [], selected: "", busy: false })
        response.message && statusDispatch({ level: response.status, message: response.message, timeout: 1500 })
        document.getElementById("uploader").value = ""
        dispatchReset()
    }

    useEffect(() => {
        window.addEventListener("dragover", dragFiles, false)
        window.addEventListener("drop", dropFiles, false)
        window.addEventListener("dragleave", dragOut, false)
        return () => {
            window.removeEventListener("dragover", dragFiles, false)
            window.removeEventListener("drop", dropFiles, false)
            window.removeEventListener("dragleave", dragOut, false)
        }
    }, [dropFiles])

    return (<>
        <CardToolBar>
            <CardToolBarTitle>Upload Your Files Here</CardToolBarTitle>
            <input id="uploader" type="file" multiple style={{ width: "0px", height: "0px", visibility: "hidden" }} onChange={addFiles} />
            <div style={{ flex: 1, textAlign: "right" }}>
                <GridActionButton action={uploadFiles} disable={fileList.data.length === 0 ? true : false}>
                    <FontAwesomeIcon icon="plus" style={{ marginRight: "5px" }} />
                    Upload Files
                </GridActionButton>
            </div>
        </CardToolBar>
        <CardToolBar>
            <div style={{ width: "66px" }}>Practice:</div>
            <div style={{ flex: 1, marginRight: "10px" }}><PracticeCombo dispatch={dispatchSelected} /></div>
            <div style={{ width: "90px" }}>Privately To:</div>
            <div className="files-grid-toolbar-field"><UserCombo parent={comboBoxes.practice} dispatch={dispatchSelected} /></div>
        </CardToolBar>
        <div id="files-drop-wrapper">
            <div id="files-drop-container" onClick={() => document.getElementById("uploader").click()}>
                <h2>Drag And Drop Your Files Here</h2>
                <h3>Or Click To Select Your Files</h3>
            </div>
        </div>
        <GridContent busy={fileList.busy}>
            {buildColHeader(gridColumns)}
            {buildRows(gridColumns, fileList.data, fileList.selected, updateSelected)}
        </GridContent>
    </>)
}