import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react"
import { CardButton, LinkButton } from "../../../components/buttons/Buttons";
import { Card, CardContent, CardFooter, CardHeader } from "../../../components/cards/Cards";
import { getFieldValues, initFormState, setFieldValues, useFormSubmit } from "../../../components/form/Form"
import { InputField, InputHidden, InputTextArea, MaskedInputField } from "../../../components/inputs/Inputs";
import { YesNoContext } from "../../contexts/YesNoContext";
import { useMousePosition } from "../../hooks/UseMousePosition";
import { PortalCombo} from '../../combos/PortalCombo';
import { formApi } from "../../Toolbox";

export const PasswordForm = ({ parent, record, callback }) => {

    const mousePos = useMousePosition()
    const [formState, setFormState] = useState(initFormState("password-form"));
    const [yesNoState, yesNoDispatch] = useContext(YesNoContext)
    const [portal,setPortal] = useState(record.pordata ? [record.pordata.id] : [])
    const formProcess = useFormSubmit(formState.id, formState.url, formState.verb);
    

    const submitForm = async(e, deleteFlag = false) => {
        setFormState(ps => ({ ...ps, busy: true }))
        let payload = getFieldValues(formState.id) 
        payload.append("parent",parent)               
        payload.append("pordataid", portal[0])        
        let resp = await formApi(record.id, "passwords", payload, deleteFlag)
        resp.status === "success" && callback({ status: "success", data: resp.data.id })
        resp.errors && setFormState(ps => ({ ...ps, errors: resp.errors, busy: false }))
    }

    const confirmDelete = (resp) => {
        yesNoDispatch({ message: "", top: "", left: "", dispatch: undefined })
        resp && submitForm(null, true)
    }

    const handleComboChange = (id,val) => {
        setPortal(val)
    }

    const handleDelete = () => { 
        yesNoDispatch({ message: "Deactivate This Record! Are you Sure?", left: mousePos.x, top: mousePos.y, dispatch: confirmDelete }) 
    }

    useEffect(() => {         
        setFieldValues(formState.id, record) 
    }, [])

    return (
        <Card id={formState.id}>
            <CardHeader>
                <div className="card-header-text">{record.id ? record.pordata.value : "Adding New Password Profile"}</div>
                <div className="card-header-icon">{formState.busy ? <FontAwesomeIcon icon="fa-spinner" spin /> : <></>}</div>
            </CardHeader>
            <CardContent style={{padding: "10px"}}>
                <InputHidden id="id" inputAttrib={{ defaultValue: record.id }} />
                <PortalCombo 
                    id="portal-combo" 
                    label="Select The Web/Portal"
                    selected = {portal}
                    dispatch = {handleComboChange}
                    error = {formState.errors.portal}
                />
                <InputField id="username" label="User Name" error={formState.errors.username || ""} inputAttrib={{ autoFocus: true }} />
                <InputField id="password" label="Password" error={formState.errors.password || ""} />
                <InputField id="emailaddress" label="Associated Email" />
                <InputTextArea id="addinformation" label="Additional Information" inputStyle={{ height: "100px" }} />
            </CardContent>
            <CardFooter>
                <div className="form-card-footer left">
                    {record.id
                        ? <LinkButton action={handleDelete}><FontAwesomeIcon icon="trash" style={{ marginRight: "3px" }}></FontAwesomeIcon>Deactivate</LinkButton>
                        : <></>
                    }
                </div>
                <div className="form-card-footer button"><CardButton action={submitForm}>Save</CardButton></div>
                <div className="form-card-footer button"><CardButton action={() => callback({ status: "cancel" })}>Cancel</CardButton></div>
            </CardFooter>
        </Card>
    )
}