import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useRef, useState, useEffect } from "react";
import { createContext } from "react";
import { CardButton } from "../../components/buttons/Buttons";

export const initYesNoParams = {
    message: "",
    dispatch: null,
    top: 0,
    left: 0,
    offset: "left"
}

const YesNoCard = () => {
    const [yesNoState, yesNoDispatch] = useContext(YesNoContext)
    let yesNoRef = useRef(0)
    let top = yesNoState.top
    let left = yesNoState.left
    if (yesNoState.offset === "right"){        
        let offsetLeft = parseInt(left.replace("px",""));
        left = `${offsetLeft-300}px`
    }
    useEffect(() => { setTimeout(() => { yesNoRef.current.classList.add("open") }, 100) }, [])

    return (
        <div className="application-modal">
            <div className="yesno-card" ref={yesNoRef} style={{ top: top, left: left }}>
                <div className="status-card-icon warning"><FontAwesomeIcon icon="circle-question" /></div>
                <div className="status-card-text">{yesNoState.message}</div>
                <div className="status-card-foot">
                    <CardButton style={{ width: "80px", marginRight: "5px" }} action={() => yesNoState.dispatch(true)}>Yes</CardButton>
                    <CardButton style={{ width: "80px", marginLeft: "5px" }} action={() => yesNoState.dispatch(false)}>No</CardButton>
                </div>
            </div>
        </div>
    )
}

export const YesNoContext = createContext()
export const YesNoContextProvider = ({ children }) => {
    const [yesNoState, yesNoDispatch] = useState(initYesNoParams)
    return (
        <YesNoContext.Provider value={[yesNoState, yesNoDispatch]}>
            {children}
            {yesNoState.message ? <YesNoCard /> : ""}
        </YesNoContext.Provider>
    )
};