
import { useEffect, useState } from "react"
import { InputSelect } from "../../components/inputs/Inputs"
import { getApi } from "../Toolbox"

export const PayMethodCombo = ({ parent, selected, dispatch, label }) => {
    const [comboItems, setComboItems] = useState({ data: [], selected: selected || []})

    const dispatchSelected = (id,val) => {
        setComboItems(ps => ({ ...ps, selected: val }))
        dispatch(id,val)
    }

    useEffect(() => {
        const loadData = async() => {            
            let url = `ledgertype?parent=${parent}&ledgertype=1&combo=1&page=1&limit=500`
            let resp = await getApi(url)     
            let newSelected = selected
            if (!newSelected.length){             
                let rec = resp.data.find(r=>r.default)
                newSelected = rec ? [rec.id] : []
            }            
            resp.status === "success" && setComboItems({ data: resp.data, selected:newSelected})            
            dispatch("paymethod", newSelected)
        }
        loadData()
    },[parent]);

    return (
        <InputSelect
            id="paymethod-combo"
            label={label}
            items={comboItems.data}
            selected={comboItems.selected}
            setSelected={dispatchSelected}            
        />
    )
}