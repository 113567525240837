import { useState } from "react"
import { PortalGrid } from "./PortalGrid"
import { PracticeGrid } from "./PracticeGrid"
import { UserGrid } from "./UserGrid"
import { ProviderGrid } from "./ProviderGrid"
import { Card, CardContent, CardHeader } from "../../../components/cards/Cards"
import { Tabs } from "../../../components/tabs/Tabs"

export const Admin = () => {
    const [parent, setParent] = useState("")
    const [curTab, setCurTab] = useState(0)
    const tabItems = ["Practices", "Web Portals"]
    return (
        <div className="admin-wrapper">
            <div className="admin-tab-container">
                <Tabs items={tabItems} dispatch={(val) => setCurTab(val)} />
            </div>
            {curTab === 0 &&
                <div className="admin-main-container">
                    <Card style={{ width: "100%", flex: 1 }} nomodal>
                        <CardHeader>Administration</CardHeader>
                        <CardContent>
                            <div className="admin-container">
                                <div className="admin-left-container">
                                    <PracticeGrid dispatch={(val) => setParent(val)}></PracticeGrid>
                                </div>
                                <div className="admin-right-container">
                                    <div className="admin-top-container">
                                        <UserGrid parent={parent} />
                                    </div>
                                    <div className="admin-bot-container">
                                        <ProviderGrid parent={parent} />
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            }
            {curTab === 1 &&
                <div className="admin-main-container">
                    <Card style={{ width: "100%", flex: 1 }} nomodal>
                        
                        <div style={{display:"flex",flexFlow:"column",width:"100%",flex:1,backgroundColor:"var(--card-background)"}}>
                            
                                <PortalGrid />
                            
                        </div>
                    </Card>
                </div>
            }
        </div >
    )
}