import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ApplicationContext } from "../../contexts/AppContext"
import { StatusContext } from "../../contexts/StatusContext"
import { getApi } from "../../Toolbox"
import { Admin } from "../administration/Admin"
import { Files } from "../files/Files"
import { LedgerGrid } from "../ledger/LedgerGrid"
import { Messaging } from "../messaging/Messaging"
import { PasswordProvider } from "../passwordmgr/PasswordContext"
import { Passwords } from "../passwordmgr/Passwords"
import { Settings } from "../settings/Settings"
import { PortalFooter } from "./PortalFooter"
import { PortalNav } from "./PortalNav"
import { PortalTopNav } from "./PortalTopNav"


export const Portal = () => {
    const nav = useNavigate()
    const [applicationState, setApplicationState] = useContext(ApplicationContext)
    const [statusState, statusDispatch] = useContext(StatusContext)
    const [currentPage, setCurrentPage] = useState(1)

    const updateApplicationState = async () => {
        let resp = await getApi(`users/fetch`)
        resp.status === 200 ? setApplicationState(resp.data) : nav("/login", { replace: true })
        resp.message && statusDispatch({ level: resp.status, message: resp.message, timeout: 2000 })
    }

    useEffect(() => { updateApplicationState() }, [])

    return (
        <div className="portal-container">
            <PortalTopNav />
            <div className="portal-content">
                <PortalNav page={currentPage} action={setCurrentPage} />
                {currentPage == 1 && <Messaging />}
                {currentPage == 2 && <LedgerGrid />}
                {currentPage == 3 && <Files />}
                {currentPage == 4 && <PasswordProvider><Passwords /></PasswordProvider>}
                {currentPage == 5 && <Settings />}
                {currentPage == 6 && <Admin />}
            </div>
            <PortalFooter />
        </div>
    )
}