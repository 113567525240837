import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react"
import { CardButton } from "../../../components/buttons/Buttons";
import { Card, CardContent, CardFooter, CardHeader } from "../../../components/cards/Cards";
import { getFieldValues, initFormState, setFieldValues } from "../../../components/form/Form"
import { InputField } from "../../../components/inputs/Inputs";
import { postApi, getFancyDate } from "../../Toolbox";
import { UserMailCombo } from "../../combos/UserMailCombo";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export const MessageForm = ({ record, callback, msgtype }) => {
    const [formState, setFormState] = useState(initFormState("user-form"));
    const [recipList, setRecipList] = useState([])
    const [prevMessage, setPrevMessage] = useState("")
    const [isOpen,setIsOpen] = useState(false)
    const [messageText, setMessageText] = useState("")
    const editorRef = useRef(null)

    const submitForm = async (e) => {
        setFormState(ps => ({ ...ps, busy: false }))
        let data = getFieldValues(formState.id)
        data.append("id", "")
        data.append("msgtext", messageText + prevMessage)
        data.append("distlist", JSON.stringify(recipList))
        let resp = await postApi("messages", data)
        resp.status === "success" && callback({ status: "success", data: resp.data.id })
        resp.errors && setFormState(ps => ({ ...ps, errors: resp.errors, busy: false }))
    }

    const updateDistribution = (id, val) => setRecipList(val);

    useEffect(() => {
        if (record.id) {
            msgtype === 1 && setRecipList([record.from.id])
            if (msgtype === 2) {
                let disId = [record.from.id]
                record.distlist.forEach(r => disId.push(r.id));
                setRecipList(disId)
            }
            let disStr = []
            record.distlist.forEach(r => disStr.push(` ${r.name}`));
            let prevMsg = `${"=".repeat(20)}[ ORIGINAL MESSAGE ]${"=".repeat(20)}<br />`
            prevMsg += ` From: ${record.from.name}<br />`
            prevMsg += ` To: ${disStr.toString()}<br/>`
            prevMsg += ` Date: ${record.msgdate}<br />`
            prevMsg += ` Subject: ${record.subject}<br />`
            prevMsg += `${"=".repeat(59)}<br />`
            prevMsg += record.msgtext
            setPrevMessage(prevMsg);
            setFieldValues(formState.id, record)            
        }
    }, [])

    useEffect(() => {        
        isOpen && record.id && editorRef.current.editing.view.focus();        
    }, [isOpen])

    

    return (
        <Card id={formState.id} style={{ width: "600px" }}>
            <CardHeader>
                <div className="card-header-text">Message Editor</div>
                <div className="card-header-icon">{formState.busy ? <FontAwesomeIcon icon="fa-spinner" spin /> : <></>}</div>
            </CardHeader>
            <CardContent style={{ padding: "10px" }}>
                <div className="form-row">
                    <div style={{ width: "200px", marginRight: "3px" }}>
                        <InputField id="msgdate" label="Message Date" inputAttrib={{ readOnly: true, value: getFancyDate() }} />
                    </div>
                    <div style={{ flex: 1, marginLeft: "3px" }}>
                        <InputField id="subject" label="Message Subject" inputAttrib={{ autoFocus: true }} error={formState.errors.subject || ""} disabled={record.id} />
                    </div>
                </div>
                <UserMailCombo selected={recipList} label="Message Recipient(s)" dispatch={updateDistribution} error={formState.errors.usercombo || ""} />
                <CKEditor
                    editor={ClassicEditor}
                    onReady={(editor) => {
                         editorRef.current = editor 
                         setIsOpen(true)
                    }}
                    onChange={(event, editor) => setMessageText(editor.getData())}
                    config={{height: "100px"}}
                />
                {record.id && <div className="message-editor-prev" dangerouslySetInnerHTML={{ __html: prevMessage }}></div>}
            </CardContent>
            <CardFooter>
                <div className="form-card-footer left"></div>
                <div className="form-card-footer button"><CardButton action={submitForm}>Send</CardButton></div>
                <div className="form-card-footer button"><CardButton action={() => callback({ status: "cancel" })}>Cancel</CardButton></div>
            </CardFooter>
        </Card>
    )
}