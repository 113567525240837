import { useState } from "react";
import { CardButton } from "../../../components/buttons/Buttons";
import { PayForCombo } from "../../combos/PayForCombo";


export const PayForFilter = (props) => {
    const [picked, setPicked] = useState([props.payFor]);

    const submitForm = () => props.dispatch("payfor", picked)
    const reset = () => props.dispatch("payfor", [""])

    return (
        <div className="card-modal">
            <div className="card-container bordertop" style={{ width: "250px", position: "absolute", top: props.mouseTop, left: props.mouseLeft }}>
                <div style={{ marginBottom: "20px" }}></div>
                <div className="card-content form">
                    <PayForCombo
                        label="Select The Payment For"
                        parent={props.parent}
                        selected={picked}
                        dispatch={(id, val) => setPicked(val)}
                    />
                </div>
                <div className="card-footer form">
                    <div><CardButton small action={reset}>Reset</CardButton></div>
                    <div style={{ flex: 1, textAlign: "right" }}>
                        <CardButton small action={submitForm}>Filter</CardButton>
                    </div>
                    <CardButton small action={() => props.dispatch("cancel", "")} style={{ marginLeft: "5px" }}>Cancel</CardButton>
                </div>
            </div>
        </div >
    )
}