import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { CardButton, LinkButton } from "../../../components/buttons/Buttons"
import { Card, CardContent, CardFooter, CardFormHeader } from "../../../components/cards/Cards"
import { initFormState, useFormSubmit } from "../../../components/form/Form"
import { InputField } from "../../../components/inputs/Inputs"

export const ForgotPwdForm = ({ page }) => {
    let nav = useNavigate()
    const [formState, setFormState] = useState(initFormState("forgotpwd-form"))
    const formProcess = useFormSubmit(formState.id, formState.url, formState.verb)

    const submitForm = async (deactivate = "") => {
        let newUrl = `login/forgotpwd?loader=${formState.loader}`
        setFormState(ps => ({ ...ps, url: newUrl, busy: true, verb: "POST", loader: !formState.loader }))
    }

    useEffect(() => {
        setFormState(ps=>({...ps,busy:false}))
         formProcess.status === "success" && nav("/login", { replace: true })          
         formProcess.errors && setFormState(ps=>({...ps,errors:formProcess.errors}))
    }, [formProcess])

    return (
        <div className="login-container">
            <div className="login-container-upper"></div>
            <div className="login-container-lower"></div>
            <div className="application-modal" nodim="true">
                <Card id={formState.id} style={{ width: "380px" }}>
                    <CardFormHeader title="Forgot Password" busy={formState.busy} />
                    <CardContent style={{padding: "10px"}}>                        
                        <InputField id="userid" label="Please Enter Your Email Address" error={formState.errors.userid || ""} inputAttrib={{ autoFocus: true }} />
                        <div className="login-miranda" style={{ padding: "0px 5px" }}>
                            If the email address you enter matches an active account in the Portal, you will receive an email with instructions on how to request
                            your password.
                        </div>
                    </CardContent>
                    <CardFooter>
                        <div className="form-card-footer left">
                            <LinkButton action={() => nav("/login", { replace: true })}>Back To Login</LinkButton>
                        </div>
                        <div className="form-card-footer button">
                            <CardButton action={submitForm}>Send Request</CardButton>
                        </div>
                    </CardFooter>
                </Card>
            </div>
        </div>
    )
}