import { useEffect, useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { buildColHeader, buildRows, GridContent, GridHeader, GridLoader, GridPaging, initGridState, setGridSelect, useGetGridData } from "../../../components/grid/Grid"
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { GridActionButton } from "../../../components/buttons/Buttons";
import { PracticeForm } from "./PracticeForm";
import { useRestApi } from "../../hooks/UseRestApi";
import { CardToolBar, CardToolBarTitle } from "../../../components/cards/Cards";

export const PracticeGrid = ({ dispatch }) => {
    const [gridState, setGridState] = useState(initGridState("practice-grid"))
    const gridData = useRestApi(gridState.url)
    const [editor, setEditor] = useState({ open: false, record: {}, index: -1 })

    const gridColumns = [{
        columnId: "practicename",
        hdrText: "Practice Name",
        width: "42%",
        render: (ndx) => gridData.data[ndx].practicename
    }, {
        columnId: "contactname",
        hdrText: "Contact Name",
        width: "24%",
        render: (ndx) => gridData.data[ndx].contactname
    }, {
        columnId: "telephone",
        hdrText: "Telephone",
        width: "15%",
        render: (ndx) => gridData.data[ndx].telephone
    }, {
        columnId: "fax",
        hdrText: "Fax",
        width: "15%",
        render: (ndx) => gridData.data[ndx].fax
    }, {
        columnId: "actions",
        hdrTextheader: "",
        width: "4%",
        type: "action",
        cellStyle: { textAlign: "center",borderRight:"none" },
        render: (ndx) => (
            <div className="inner pointer" data-id={gridData.data[ndx].id} onClick={callEditor}>
                <FontAwesomeIcon icon={faPencil} color="#e7b05e" style={{ pointerEvents: "none" }} />
            </div>
        )
    }]

    const callEditor = (e) => {
        let id = e.target.getAttribute("data-id")
        let ndx = gridData.data.findIndex(r => r.id === id)
        setEditor({ open: true, record: ndx > -1 ? gridData.data[ndx] : {}, index: ndx })
    }

    const editorCallBack = (resp) => {
        setEditor({ open: false, record: {}, index: -1 })
        let selected = resp.status === "success" ? resp.data.id : gridState.selected
        setGridState(ps => ({ ...ps, loader: !gridState.loader, selected: selected }))
        dispatch(selected)
    }

    const updatePage = (page) => {
        setGridState(ps => ({ ...ps, page: page, loader: !gridState.loader }))
    }

    const updateSelected = (e) => {
        let id = e.target.getAttribute("data-id")
        setGridState(ps => ({ ...ps, selected: id  }))
        dispatch(id)
    }

    useEffect(() => {
        let selected = setGridSelect("id", gridData.data, gridState.selected, editor.index)
        setGridState(ps => ({ ...ps, busy: false, selected: selected }))
        dispatch(selected)
    }, [gridData])

    useEffect(() => {
        let newUrl = `practices?page=${gridState.page}&limit=${gridState.limit}&reset=${gridState.loader}`
        setGridState(ps => ({ ...ps, url: newUrl, busy: true }))
    }, [gridState.loader])

    return (<>
        <CardToolBar>
            <CardToolBarTitle>Practices</CardToolBarTitle>
            <div style={{ flex: 1, textAlign: "right" }}>
                <GridActionButton action={callEditor}>
                    <FontAwesomeIcon icon="plus" style={{ marginRight: "5px" }} />
                    New Practice
                </GridActionButton>
            </div>
        </CardToolBar>
        <GridContent busy={gridData.busy}>            
            {buildColHeader(gridColumns)}
            {buildRows(gridColumns, gridData.data, gridState.selected, updateSelected)}
        </GridContent>
        <GridPaging page={gridState.page} limit={gridState.limit} count={gridData.count} dispatch={updatePage} />
        {editor.open && <PracticeForm record={editor.record} callback={editorCallBack} />}
    </>)
}