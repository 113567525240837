import { createContext, useState } from "react";

export const ApplicationContext = createContext()

export const ApplicationProvider = ({children}) => {
    const [applicationState,setApplicationState] = useState({
        practiceid: "",
        practicename: "",
        master: false,
        admin: "",
        username: "",
        initials: "",
        avatar: {mime:"",stream:""}
    })
    return(<ApplicationContext.Provider value={[applicationState,setApplicationState]}>{children}</ApplicationContext.Provider>)
}