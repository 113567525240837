import { useContext, useEffect, useState } from "react"
import { CardToolBar, CardToolBarTitle } from "../../../components/cards/Cards";
import { buildColHeader, buildRows, GridContent, GridPaging, initGridState } from "../../../components/grid/Grid"
import { useRestApi } from "../../hooks/UseRestApi";
import { setGridSelected } from "../../Toolbox";
import { PasswordContext } from "./PasswordContext";

export const WebLogs = ({ reset }) => {
    const { webdata, weblogs, addWebLogs, setWebLogsSelect } = useContext(PasswordContext)
    const [gridState, setGridState] = useState(initGridState("question-grid"))
    const gridData = useRestApi(gridState.url)
    const [editor, setEditor] = useState({ open: false, record: {}, index: -1 })

    const gridColumns = [{
        columnId: "changedate",
        hdrText: "Log Date",
        width: "20%",
        render: (ndx) => weblogs.data[ndx].changedate
    }, {
        columnId: "username",
        hdrText: "User",
        width: "20%",
        type: "action",
        render: (ndx) => weblogs.data[ndx].username
    }, {
        columnId: "changed",
        hdrText: "Field",
        width: "20%",
        type: "action",
        render: (ndx) => weblogs.data[ndx].changed
    }, {
        columnId: "oldvalue",
        hdrText: "Old Value",
        width: "20%",
        type: "action",
        render: (ndx) => weblogs.data[ndx].oldvalue
    }, {
        columnId: "newvalue",
        hdrText: "New Value",
        width: "20%",
        type: "action",
        render: (ndx) => weblogs.data[ndx].newvalue
    }]

    const updatePage = (page) => {
        setGridState(ps => ({ ...ps, page: page, loader: !gridState.loader }))
    }

    const updateSelected = (e) => {
        let id = e.target.getAttribute("data-id")
        setWebLogsSelect(id)
    }

    useEffect(() => {
        let url = `weblogs?parent=${webdata.selected}&page=${gridState.page}&limit=${gridState.limit}&loader=${reset}`
        setGridState(ps => ({ ...ps, url: url }))
    }, [webdata.selected, reset, gridState.loader])

    useEffect(() => {
        let newSelect = setGridSelected("id", gridData.data, weblogs.selected, editor.index)
        addWebLogs(gridData.data)
        setWebLogsSelect(newSelect)
    }, [gridData])

    return (<>
        <CardToolBar>
            <CardToolBarTitle>Password Change Logs</CardToolBarTitle>
        </CardToolBar>
        <GridContent busy={gridData.busy}>
            {buildColHeader(gridColumns)}
            {buildRows(gridColumns, weblogs.data, weblogs.selected, updateSelected)}
        </GridContent>
        <GridPaging page={gridState.page} limit={gridState.limit} count={gridData.count} dispatch={updatePage} />
    </>)
}