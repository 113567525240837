import { createContext, useReducer } from "react";

export const PasswordContext = createContext()

const initState = {
    webdata: {data:[],selected:""},
    weblogs: {data:[],selected:""},
    secdata: {data:[],selected:""}
}

const reducer = (state,action) => {    
    switch(action.type){
        case 'add_webdata': return{
            ...state,
            webdata:{...state.webdata,data:action.payload}
        }
        case 'set_webdata': return{
            ...state,
            webdata:{...state.webdata,selected:action.payload}
        }
        case 'add_weblogs': return{
            ...state,
            weblogs:{...state.weblogs,data:action.payload}
        }
        case 'set_weblogs': return{
            ...state,
            weblogs:{...state.weblogs,selected:action.payload}
        }    
        case 'add_secdata': return{
            ...state,
            secdata:{...state.secdata,data:action.payload}
        }
        case 'set_secdata': return{
            ...state,
            secdata:{...state.secdata,selected:action.payload}
        }        
    }
}
export const PasswordProvider = ({children}) => {
    const [state,dispatch] = useReducer(reducer,initState)
    const contextValue = {
        webdata: state.webdata,
        weblogs: state.weblogs,
        secdata: state.secdata,
        addWebData: (data) => dispatch({type:"add_webdata",payload:data}),
        setWebDataSelect: (id) => dispatch({type:"set_webdata",payload:id}),
        addSecData: (data) => dispatch({type:"add_secdata",payload:data}),
        setSecDataSelect: (id) => dispatch({type:"set_secdata",payload:id}),
        addWebLogs: (data) => dispatch({type:"add_weblogs",payload:data}),
        setWebLogsSelect: (id) => dispatch({type:"set_weblogs",payload:id})
    }    
    return(<PasswordContext.Provider value={contextValue}>{children}</PasswordContext.Provider>)
}