import { useState } from "react"
import { Card, CardContent, CardHeader } from "../../../components/cards/Cards"
import { FileGrid } from "./FileGrid"
import { FileLogsGrid } from "./FileLogsGrid"
import { FileUploads } from "./FileUploads"

export const Files = () => {
    const [resetGrids, setResetGrids] = useState(false)    
    return (

        <div className="files-wrapper">
            <Card style={{ width: "100%", height: "100%" }} nomodal>
                <CardHeader>File Management</CardHeader>
                <CardContent>
                    <div className="files-container">
                        <div className="files-container-top">
                            <div className="files-container-top-left">
                                <FileUploads dispatchReset={() => setResetGrids(!resetGrids)}/>
                            </div>
                            <div className="files-container-top-right">
                                <FileGrid reset = {resetGrids} dispatchReset={() => setResetGrids(!resetGrids)}/>
                            </div>
                        </div>
                        <div className="files-container-bot">
                            <FileLogsGrid reset={resetGrids} />
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>



















        //     <div className="files-container">
        //         <div className="files-list-container"><FileGrid reset={gridReset}/></div>
        //         <div className="files-upload-container"><FileUploads reset={gridReset}/></div>
        //     </div>
        //     <div className="logs-container">
        //         <FileLogsGrid reset={reset} />
        //     </div>
        // </div>
    )
}