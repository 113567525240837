import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { GridActionButton } from "../../../components/buttons/Buttons"
import Logo from "../../assets/images/email-logo.png"
import { Logout } from "./Logout"

export const PortalTopNav = () => {
    const nav = useNavigate()
    const[dialogOpen,setDialogOpen] = useState(false)

    const handleYesNo = (resp) => {
        resp && nav("/login",{replace:true})
        setDialogOpen(false)        
    }

    return (<>
        <div className="portal-top-nav">
            <div className="portal-top-container"><img src={Logo} height="70px" alt="PPS Logo" /></div>
            <div className="portal-top-container" style={{ textAlign: "right",marginRight:"1px" }}>
                <GridActionButton style={{ width: "120px", height: "50px" }} action={()=>setDialogOpen(true)}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <FontAwesomeIcon icon="right-from-bracket" size="2x" style={{ marginTop: "2px", marginRight: "10px" }}></FontAwesomeIcon>
                        <span style={{ fontSize: "1.2rem", fontWeight: 400 }}>Logout</span>
                    </div>
                </GridActionButton>
            </div>            
        </div>
        {dialogOpen && <Logout dispatch={handleYesNo} />}
    </>)
}