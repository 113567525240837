
import { useEffect, useState } from "react"
import { InputSelect } from "../../components/inputs/Inputs"
import { getApi } from "../Toolbox"

export const UserMailCombo = ({ selected, dispatch, label, error }) => {
    const [comboItems, setComboItems] = useState({ data: [], selected: selected })

    const dispatchSelected = (id,val) => {        
        setComboItems(ps => ({ ...ps, selected: val }))
        dispatch(id,val)
    }

    useEffect(() => {        
        const loadData = async () => {
            let url = `users?combo=2&page=1&limit=1000`
            let resp = await getApi(url)                        
            resp.status === "success" && setComboItems({ data: resp.data, selected: selected })                        
        }
        loadData()
    }, [selected]);
 
    return (
        <InputSelect
            id="usercombo"
            label={label}
            items={comboItems.data}
            selected={comboItems.selected}
            setSelected={dispatchSelected}
            error = {error}
            multi            
        />
    )
}