import { library } from '@fortawesome/fontawesome-svg-core'

import {
    faEnvelope,
    faUpload,
    faBookOpen,
    faWindowRestore,
    faCog,
    faCogs,
    faAngleLeft,
    faAnglesLeft,
    faAngleRight,
    faAnglesRight,
    faPenNib,
    faCircleArrowDown,
    faCircleArrowUp,
    faPaperPlane,
    faBoxArchive,
    faTrash,
    faFilePen,
    faFlag,
    faFolder,
    faCircleNotch,
    faReply,
    faReplyAll,
    faShare,
    faPrint,
    faCaretDown,
    faSpinner,
    faBan,
    faCaretLeft,
    faXmark,
    faFileArrowDown,
    faFilter,
    faReceipt,
    faFilterCircleXmark,
    faPlus,
    faCopy,
    faRotate,
    faCircleQuestion,
    faCheck,
    faShuffle,
    faDownload,
    faEnvelopesBulk,
    faCirclePlus,
    faPencil,
    faFileInvoice,
    faMinus,
    faKey,
    faSquareCheck,
    faRightFromBracket
} from '@fortawesome/free-solid-svg-icons'
import { faSquare } from '@fortawesome/free-regular-svg-icons'

library.add(
    faEnvelope,
    faUpload,
    faBookOpen,
    faWindowRestore,
    faCog,
    faCogs,
    faAngleLeft,
    faAnglesLeft,
    faAngleRight,
    faAnglesRight,
    faPenNib,
    faCircleArrowDown,
    faCircleArrowUp,
    faPaperPlane,
    faBoxArchive,
    faTrash,
    faFilePen,
    faFlag,
    faFolder,
    faCircleNotch,
    faReply,
    faReplyAll,
    faShare,
    faPrint,
    faCaretDown,
    faSpinner,
    faBan,
    faCaretDown,
    faCaretLeft,
    faXmark,
    faFileArrowDown,
    faFilter,
    faReceipt,
    faFilterCircleXmark,
    faPlus,
    faCopy,
    faRotate,
    faCircleQuestion,
    faCheck,
    faShuffle,
    faDownload,
    faEnvelopesBulk,
    faCirclePlus,
    faPencil,
    faFileInvoice,
    faMinus,
    faKey,
    faFilterCircleXmark,
    faSquare,
    faSquareCheck,
    faRightFromBracket
 
)