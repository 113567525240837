import { useContext, useEffect, useState } from "react"
import { Card, CardContent, CardHeader, CardToolBar, CardToolBarTitle } from "../../../components/cards/Cards"
import { GridHeader, GridToolBar } from "../../../components/grid/Grid"
import { PasswordContext } from "./PasswordContext"
import { PasswordGrid } from "./PasswordGrid"
import { QuestionGrid } from "./QuestionGrid"
import { WebLogs } from "./WebLogs"

export const Passwords = () => {
    const { webdata } = useContext(PasswordContext)
    const [addInfo, setAddInfo] = useState("")
    const [resetGrids, setResetGrids] = useState(false)

    useEffect(() => {
        let rec = webdata.data.find(r => r.id === webdata.selected)
        setAddInfo(rec ? rec.addinformation : "")
    }, [webdata.selected])

    return (
        <div className="password-wrapper">
            <Card style={{ width: "100%", flex: 1 }} nomodal>
                <CardHeader>Practice Password Management</CardHeader>
                <CardContent>
                    <div className="password-container">
                        <div className="password-container-top">
                            <div className="password-container-top-left"><PasswordGrid dispatchReset={() => setResetGrids(!resetGrids)} /></div>
                            <div className="password-container-top-right">
                                <div className="password-container-top-right-top"><QuestionGrid /></div>
                                <div className="password-container-top-right-bot">
                                    <CardToolBar><CardToolBarTitle>Additional Infromation</CardToolBarTitle></CardToolBar>
                                    <div className="password-addinfo-container" dangerouslySetInnerHTML={{ __html: addInfo }}></div>
                                </div>
                            </div>
                        </div>
                        <div className="password-container-bottom"><WebLogs reset={resetGrids}/></div>
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}