import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react"
import { CardButton, LinkButton } from "../../../components/buttons/Buttons";
import { Card, CardContent, CardFooter, CardHeader } from "../../../components/cards/Cards";
import { initFormState, setFieldValues, useFormSubmit } from "../../../components/form/Form"
import { InputField, InputHidden, InputTextArea, MaskedInputField } from "../../../components/inputs/Inputs";
import { YesNoContext } from "../../contexts/YesNoContext";
import { useMousePosition } from "../../hooks/UseMousePosition";

export const PracticeForm = ({ record, callback }) => {
    const mousePos = useMousePosition()
    const [formState, setFormState] = useState(initFormState("practice-form"));
    const [yesNoState, yesNoDispatch] = useContext(YesNoContext)
    const formProcess = useFormSubmit(formState.id, formState.url, formState.verb);

    const submitForm = (e, deactivate = false) => {
        let verb = record.id ? "PUT" : "POST"
        if (deactivate) verb = "DELETE"
        let newUrl = `practices?loader=${formState.loader}`
        setFormState(ps => ({ ...ps, url: newUrl, busy: true, verb: verb, loader: !formState.loader }))
    }

    const confirmDelete = (resp) => {
        yesNoDispatch({ message: "", top: "", left: "", dispatch: undefined })
        resp && submitForm(null, true)
    }

    const handleDelete = () => { yesNoDispatch({ message: "Deactivate This Record! Are you Sure?", left: mousePos.x, top: mousePos.y, dispatch: confirmDelete }) }

    useEffect(() => { setFieldValues(formState.id, record) }, [])

    useEffect(() => {
        setFormState(ps => ({ ...ps, busy: false }))
        formProcess.status === "success" && callback({ status: "success", data: formProcess.data })
        formProcess.errors && setFormState(ps => ({ ...ps, errors: formProcess.errors }))
    }, [formProcess])

    return (
        <Card id={formState.id}>
            <CardHeader>
                <div className="card-header-text">{record.id ? record.practicename : "Adding New Practice"}</div>
                <div className="card-header-icon">{formState.busy ? <FontAwesomeIcon icon="fa-spinner" spin /> : <></>}</div>
            </CardHeader>
            <CardContent style={{padding: "10px"}}>
                <InputHidden id="id" inputAttrib={{ defaultValue: record.id }} />
                <InputField id="practicename" label="Practice Name" error={formProcess.errors.practicename || ""} inputAttrib={{ autoFocus: true }} />
                <InputField id="contactname" label="Contact Name" error={formProcess.errors.contactname || ""} />
                <InputTextArea id="address" label="Practice Address" error={formProcess.errors.address || ""} inputStyle={{ height: "50px" }} />
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1, marginRight: "3px" }}><MaskedInputField id="taxid" mask="ein" label="Tax ID/EIN" error={formProcess.errors.taxid || ""} /></div>
                    <div style={{ flex: 1, marginLeft: "3px" }}><MaskedInputField id="npi" mask="npi" label="NPI" error={formProcess.errors.npi || ""} /></div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1, marginRight: "3px" }}><MaskedInputField id="telephone" mask="phone" label="Telephone" error={formProcess.errors.telephone || ""} /></div>
                    <div style={{ flex: 1, marginLeft: "3px" }}><MaskedInputField id="fax" mask="phone" label="Fax" error={formProcess.errors.fax || ""} /></div>
                </div>
                <InputField id="website" label="Web URL" />
            </CardContent>
            <CardFooter>
                <div className="form-card-footer left">
                    {record.id
                        ? <LinkButton action={handleDelete}><FontAwesomeIcon icon="trash" style={{ marginRight: "3px" }}></FontAwesomeIcon>Deactivate</LinkButton>
                        : <></>
                    }
                </div>
                <div className="form-card-footer button"><CardButton action={submitForm}>Save</CardButton></div>
                <div className="form-card-footer button"><CardButton action={() => callback({ status: "cancel" })}>Cancel</CardButton></div>
            </CardFooter>
        </Card>
    )
}