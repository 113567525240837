//id
//toolbar
//footer
//title
//busy
//style

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { CardHeader } from "../cards/Cards"
import { Config } from "../../application/Config";
import { useContext, useEffect, useState } from 'react';
import { GridNavButton } from '../buttons/Buttons';
import { StatusContext } from '../../application/contexts/StatusContext';

export const initGridState = (id, limit = 25) => ({
    id: id,
    url: "",
    busy: false,
    page: 1,
    limit: limit,
    selected: "",
    loader: false,
})

export const useGetGridData = (id, url) => {
    const [result, setResult] = useState({ status: 0, data: [], message: "", errors: {}, count: 0, total: 0 })
    const [statusState, statusDispatch] = useContext(StatusContext)
    useEffect(() => {
        if (url) {
            let headers = { credentials: "include", method: "GET", headers: { 'Content-Type': "application/json" } }
            let api = new URL(`${Config.apiUrl}/${url}`)
            fetch(api, { ...headers })
                .then(resp => resp.json())
                .then(data => {
                    setResult({ status: data.status, data: data.data, message: data.message || "", errors: data.errors || {}, count: data.count || 0, total: data.total || 0 })
                    if (data.message) statusDispatch({ level: data.status, message: data.message, timeout: 2000 })
                })
        }
    }, [url])
    return result
}

export const buildColHeader = (columns) => {
    return (
        <GridRowHeader>
            {columns.map((c, cndx) => {
                let hstyle = c.hdrStyle || {}
                if (c.width) {
                    hstyle = Object.assign(hstyle, { width: c.width })
                    return (<GridHeaderCell style={hstyle || {}} key={cndx}>{c.hdrText}</GridHeaderCell>)
                }
            })}
        </GridRowHeader>
    )
}

export const buildRows = (columns, data, selected, rowClick, rowStyle = {}) => {    
    if (typeof (data) === "object" && data.length) {
        return data.map((r, rndx) => {
            return (
                <GridRow id={r.id} selected={r.id === selected} action={rowClick} data-id={r.id} key={`r${rndx}`} style={rowStyle}>
                    {columns.map((c, cndx) => {
                        let cstyle = c.cellStyle || {}
                        if (c.width) {
                            cstyle = Object.assign(cstyle, { width: c.width })
                            if (c.type === "action") {
                                return (<GridActionCell style={cstyle || {}} key={`${rndx}-${cndx}`}>{c.render(rndx)}</GridActionCell>)
                            } else {
                                return (<GridCell style={cstyle || {}} key={`${rndx}-${cndx}`}>{c.render(rndx)}</GridCell>)
                            }
                        }
                    })}
                </GridRow>
            )
        })
    }
}

export const setGridSelect = (key, grid, selected, index) => {
    let rows = grid.length
    if (!selected) {
        return rows ? grid[0][key] : "";
    } else {
        if (grid.find(r => r[key] === selected)) return (selected)
        if (rows) {
            if (index === 0 || index === -1) return grid[0][key];
            if (index > (rows - 1)) return grid[index - 1][key];
            return grid[index][key];
        }
    }
    return ""
}

export const GridHeader = ({ title, children }) => {
    return (
        <CardHeader>
            <div className="card-header-text">{title}</div>
            {children}
        </CardHeader>
    )
}

export const GridToolBar = ({ children, style }) => {
    return (<div className="grid-toolbar" style={style || {}}>{children}</div>)
}

export const GridRowHeader = ({ children }) => {
    return (<div className="grid-row-header" >{children}</div>)
}

export const GridRow = ({ children, id, selected, action, style }) => {
    let newClass = `grid-row${selected ? " selected" : ""}`
    return (<div className={newClass} data-id={id} onClick={action} style={style || {}}>{children}</div>)
}

export const GridCell = ({ children, style }) => {
    return (<div className="grid-cell" style={style || {}}>{children}</div>)
}

export const GridHeaderCell = ({ children, style }) => {
    return (<div className="grid-header-cell" style={style || {}}>{children}</div>)
}

export const GridActionCell = ({ children, style }) => {
    return (<div className="grid-action-cell" style={style || {}}>{children}</div>)
}

export const GridContent = ({ children, style, busy }) => {
    return (
        <div className="grid-container" style={style || {}}>
            {busy ? <GridLoader /> : <div className="grid-scroll">{children}</div>}
        </div>
    )
}
export const GridRowFooter = ({ children, style }) => {
    return (<div className="grid-row-footer" style={style || {}}>{children}</div>)
}

export const GridPaging = ({ page, limit, count, dispatch }) => {
    const [pager, setPager] = useState({ pages: 1, text: "Page 1 of 1" })

    useEffect(() => {
        let pages = 1;
        if (count) {
            pages = parseInt(count / limit);
            if ((count / limit) > pages) pages += 1;
        }
        setPager({ pages: pages, text: `Page ${page} of ${pages}` })
    }, [page, count, limit])

    const clickHandler = (dir) => {
        let newPage = page
        switch (dir) {
            case "ll":
                newPage = 1
                break;
            case "l":
                if (page > 1) newPage -= 1
                break;
            case "r":
                if (page < pager.pages) newPage += 1
                break;
            case "rr":
                newPage = pager.pages
                break;
        }
        dispatch(newPage)
    }
    return (
        <div className="grid-nav-container">
            <div className="nav-region">
                <GridNavButton disable={page == 1} action={() => clickHandler("ll")}><FontAwesomeIcon icon={faAnglesLeft} /></GridNavButton>
                <GridNavButton disable={page == 1} action={() => clickHandler("l")}><FontAwesomeIcon icon={faAngleLeft} /></GridNavButton>
                <div className="page-input">{pager.text}</div>
                <GridNavButton disable={page == pager.pages} action={() => clickHandler("r")}><FontAwesomeIcon icon={faAngleRight} /></GridNavButton>
                <GridNavButton disable={page == pager.pages} action={() => clickHandler("rr")}><FontAwesomeIcon icon={faAnglesRight} /></GridNavButton>
            </div>
        </div>
    )
}
export const GridLoader = () => {
    return (
        <div className="grid-loader-spinner">
            <FontAwesomeIcon icon="spinner" spin size="3x"></FontAwesomeIcon>
            <div style={{ marginTop: "10px" }}>Loading Data</div>
        </div>
    )
}