import { useState } from "react"

export const Tabs = ({items,dispatch}) => {
    const [currentTab,setCurrentTab] = useState(0)

    const selectTab = (val) => {
        setCurrentTab(val)
        dispatch(val)
    }

    const buildTabs = () => {
        return items.map((r,ndx)=>{
            return(
                <div key={ndx} className={`tab-container-item${ndx===currentTab ? " selected": ""}`} onClick={()=>selectTab(ndx)}>{r}</div>
            )
        })
    }

    return(<div className="tab-container">{buildTabs()}</div>)
}