import { useState, useEffect, useContext } from 'react';
import { StatusContext } from '../contexts/StatusContext';
import { Config } from "../../application/Config";

export const useRestApi = (url,reset) => {
  const [statusState, statusDispatch] = useContext(StatusContext)
  const [response, setResponse] = useState({ status: "", message: "", errors: "", data: "", count: 0, busy: true })

  useEffect(() => {
    const fetchData = async () => {
      if (url) {                
        let headers = { credentials: "include", method: "GET", headers: { 'Content-Type': "application/json" } }
        let api = new URL(`${Config.apiUrl}/${url}`)
        const response = await fetch(api, { ...headers })
        const json = await response.json();
        if (json) {
          setResponse({
            status: json.status,
            message: json.message || "",
            errors: json.errors || {},
            data: json.data || null,
            count: json.count || 0,
            total: json.total || 0,
            busy: false,
          })
        }
      }
    };
    fetchData();
  }, [url]);
  return response;
};
