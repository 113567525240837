import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react"
import { CardButton, LinkButton } from "../../../components/buttons/Buttons";
import { Card, CardContent, CardFooter, CardHeader } from "../../../components/cards/Cards";
import { initFormState, setFieldValues, useFormSubmit } from "../../../components/form/Form"
import { InputField, InputHidden, InputTextArea, MaskedInputField } from "../../../components/inputs/Inputs";
import { YesNoContext } from "../../contexts/YesNoContext";
import { useMousePosition } from "../../hooks/UseMousePosition";

export const FolderForm = ({ record, parent, callback }) => {
    const mousePos = useMousePosition()
    const [formState, setFormState] = useState(initFormState("user-form"));
    const [yesNoState, yesNoDispatch] = useContext(YesNoContext)
    const formProcess = useFormSubmit(formState.id, formState.url, formState.verb);
    
    const submitForm = (e, deactivate = false) => {
        let verb = record.id ? "PUT" : "POST"
        if (deactivate) verb = "DELETE"
        let newUrl = `folders?loader=${formState.loader}`
        setFormState(ps => ({ ...ps, url: newUrl, busy: true, verb: verb, loader: !formState.loader }))
    }

    const confirmDelete = (resp) => {
        yesNoDispatch({ message: "", top: "", left: "", dispatch: undefined })
        resp && submitForm(null, true)
    }

    const handleDelete = () => { yesNoDispatch({ message: "Deactivate This Record! Are you Sure?", left: mousePos.x, top: mousePos.y, dispatch: confirmDelete }) }

    useEffect(() => { 
        let newRec = {...record}
        if (!newRec.parent) newRec.parent = parent
        setFieldValues(formState.id, newRec) 
    }, [])

    useEffect(() => {
        setFormState(ps => ({ ...ps, busy: false }))
        formProcess.status === "success" && callback({ status: "success", data: formProcess.data })
        formProcess.errors && setFormState(ps => ({ ...ps, errors: formProcess.errors }))
    }, [formProcess])

    return (
        <Card id={formState.id}>
            <CardHeader>
                <div className="card-header-text">{record.id ? record.foldername : "Adding New Folder"}</div>
                <div className="card-header-icon">{formState.busy ? <FontAwesomeIcon icon="fa-spinner" spin /> : <></>}</div>
            </CardHeader>
            <CardContent style={{padding: "10px"}}>
                <InputHidden id="id"/>                                
                <InputField id="foldername" label="Folder Name" error={formProcess.errors.foldername || ""} inputAttrib={{ autoFocus: true }}/>
            </CardContent>
            <CardFooter>
                <div className="form-card-footer left">
                    {record.id
                        ? <LinkButton action={handleDelete}>
                            <FontAwesomeIcon icon="trash" style={{ marginRight: "3px" }}></FontAwesomeIcon>Deactivate
                          </LinkButton>
                        : <></>
                    }
                </div>
                <div className="form-card-footer button"><CardButton action={submitForm}>Save</CardButton></div>
                <div className="form-card-footer button"><CardButton action={() => callback({ status: "cancel" })}>Cancel</CardButton></div>
            </CardFooter>
        </Card>
    )
}