import { Config } from "./Config"

export const getApi = async (url) => {
    let headers = { credentials: "include", method: "GET", headers: { 'Content-Type': "application/json" } }
    let api = new URL(`${Config.apiUrl}/${url}`)
    let request = await fetch(api, { ...headers })
    let response = await request.json()
    return response
}

export const postApi = async (url, formData) => {
    let headers = { credentials: "include", method: "POST", body: formData }
    let api = new URL(`${Config.apiUrl}/${url}`)    
    let request = await fetch(api, { ...headers })
    let response = await request.json()
    return response
}
export const putApi = async (url, formData) => {
    let headers = { credentials: "include", method: "PUT", body: formData }
    let api = new URL(`${Config.apiUrl}/${url}`)
    let request = await fetch(api, { ...headers })
    let response = await request.json()
    return response
}
export const deleteApi = async (url, formData) => {
    let headers = { credentials: "include", method: "DELETE", body: formData }
    let api = new URL(`${Config.apiUrl}/${url}`)
    let request = await fetch(api, { ...headers })
    let response = await request.json()
    return response
}
export const formApi = async (id, url, formData, delFlag = false) => {
    let verb = id ? "PUT" : "POST";
    if (delFlag) verb = "DELETE";
    let headers = { credentials: "include", method: verb, body: formData }
    let api = new URL(`${Config.apiUrl}/${url}`)
    let request = await fetch(api, { ...headers })
    let response = await request.json()
    return response
}

export const download_file = async (url, filename, filetype) => {
    let headers = { credentials: "include", method: "GET", headers: { 'Content-Type': "application/json" } }
    let api = new URL(`${Config.apiUrl}/${url}`)
    let request = await fetch(api, { ...headers })
    let file = await request.blob()
    var url = window.URL.createObjectURL(file)    
    let a = document.getElementById("downloader")        
    a.href = url;
    a.fileName = filename
    filetype.toLowerCase() !== "pdf" ? a.download = filename : a.target = "_blank";
    a.click()
}

export const getFancyDate = () => {
    const monthArray = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "october", "November", "December"]
    let date = new Date()
    let month = monthArray[date.getMonth()]
    let day = date.getDate()
    let year = date.getFullYear()
    let hour = date.getHours()
    let min = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    let merd = "AM"
    if (hour > 12) {
        hour = hour - 12;
        merd = "PM"
    }
    return `${month} ${day}, ${year} at ${hour}:${min}${merd}`
}

export const friendlyDate = (dateStr) => {   
    let newDate = ""
    if(dateStr) newDate=`${dateStr.slice(5,7)}/${dateStr.slice(8,10)}/${dateStr.slice(0,4)}`    
    return(newDate)
}

export const setGridSelected = (key, grid, selected, index) => {
    let rows = grid.length
    if (!rows) return ""
    if (!selected) {        
        return rows ? grid[0][key] : "";
    } else {
        if (grid.find(r => r[key] === selected)) return (selected)
        if (rows) {
            if (index === 0 || index===-1) return grid[0][key];
            if (index > (rows - 1)) return grid[index - 1][key];            
            return grid[index][key];
        }
    }
    return ""
}