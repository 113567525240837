import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react"
import { CardButton, LinkButton } from "../../../components/buttons/Buttons";
import { Card, CardContent, CardFooter, CardHeader } from "../../../components/cards/Cards";
import { getFieldValues, initFormState, setFieldValues, useFormSubmit } from "../../../components/form/Form"
import { InputField, InputHidden } from "../../../components/inputs/Inputs";
import { YesNoContext } from "../../contexts/YesNoContext";
import { useMousePosition } from "../../hooks/UseMousePosition";
import { formApi } from "../../Toolbox";
import { PasswordContext } from "./PasswordContext";

export const QuestionForm = ({ parent, record, callback }) => {
    const {webdata} = useContext(PasswordContext)
    const mousePos = useMousePosition()
    const [formState, setFormState] = useState(initFormState("password-form"));
    const [yesNoState, yesNoDispatch] = useContext(YesNoContext)    
    const formProcess = useFormSubmit(formState.id, formState.url, formState.verb);
    
    const submitForm = async(e, deleteFlag = false) => {
        setFormState(ps => ({ ...ps, busy: true }))
        let payload = getFieldValues(formState.id) 
        
        payload.append("parent",webdata.selected)                               
        let resp = await formApi(record.id, "security", payload, deleteFlag)
        resp.status === "success" && callback({ status: "success", data: resp.data.id })
        resp.errors && setFormState(ps => ({ ...ps, errors: resp.errors, busy: false }))
    }

    const handleDelete = () => { 
        yesNoDispatch({ message: "Deactivate This Record! Are you Sure?", left: mousePos.x, top: mousePos.y, dispatch: confirmDelete }) 
    }

    const confirmDelete = (resp) => {
        yesNoDispatch({ message: "", top: "", left: "", dispatch: undefined })
        resp && submitForm(null, true)
    }

    useEffect(() => {         
        setFieldValues(formState.id, record) 
    }, [])

    return (
        <Card id={formState.id}>
            <CardHeader>
                <div className="card-header-text">Security Question Editor</div>
                <div className="card-header-icon">{formState.busy ? <FontAwesomeIcon icon="fa-spinner" spin /> : <></>}</div>
            </CardHeader>
            <CardContent style={{padding: "10px"}}>
                <InputHidden id="id" inputAttrib={{defaultValue:record.id}} />
                <InputField id="question" label="Security Question" error={formState.errors.question || ""} inputAttrib={{ autoFocus: true }} />
                <InputField id="answer" label="Security Response" error={formState.errors.answer || ""} />
            </CardContent>
            <CardFooter>
                <div className="form-card-footer left">
                    {record.id
                        ? <LinkButton action={handleDelete}><FontAwesomeIcon icon="trash" style={{ marginRight: "3px" }}></FontAwesomeIcon>Deactivate</LinkButton>
                        : <></>
                    }
                </div>
                <div className="form-card-footer button"><CardButton action={submitForm}>Save</CardButton></div>
                <div className="form-card-footer button"><CardButton action={() => callback({ status: "cancel" })}>Cancel</CardButton></div>
            </CardFooter>
        </Card>
    )
}