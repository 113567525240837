import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { CardButton, LinkButton } from "../../../components/buttons/Buttons"
import { Card, CardContent, CardFooter, CardFormHeader } from "../../../components/cards/Cards"
import { initFormState, useFormSubmit } from "../../../components/form/Form"
import { InputField, InputHidden } from "../../../components/inputs/Inputs"

export const ResetPwdForm = ({ page }) => {
    let nav = useNavigate()
    const [formState, setFormState] = useState(initFormState("forgotpwd-form"))
    const formProcess = useFormSubmit(formState.id, formState.url, formState.verb)
    const { id } = useParams()
    
    const submitForm = async (deactivate = "") => {
        let newUrl = `login/resetpwd?loader=${formState.loader}`

        setFormState(ps => ({ ...ps, url: newUrl, busy: true, verb: "POST", loader: !formState.loader }))
    }

    useEffect(() => { 
        setFormState(ps=>({...ps,busy:false}))
         formProcess.status === "success" && nav("/login", { replace: true })          
         formProcess.errors && setFormState(ps=>({...ps,errors:formProcess.errors}))    
    }, [formProcess])

        console.log(id)


    return (
        <div className="login-container">
            <div className="login-container-upper"></div>
            <div className="login-container-lower"></div>
            <div className="application-modal" nodim="true">
                <Card id={formState.id} style={{ width: "380px" }}>
                    <CardFormHeader title="Reset Password" busy={formState.busy} />
                    <CardContent style={{padding: "10px"}}>
                        <input style={{visibility:"hidden"}} id="id" defaultValue={id} />
                        <InputField id="npassword" label="New Password" error={formState.errors.userid || ""} inputAttrib={{ autoFocus: true, type: "password" }} />
                        <InputField id="cpassword" label="Confirm Password" error={formState.errors.userid || ""} inputAttrib={{ type: "password" }} />
                    </CardContent>
                    <CardFooter>
                        <div className="form-card-footer left">
                            <LinkButton action={() => nav("/login", { replace: true })}>Back To Login</LinkButton>
                        </div>
                        <div className="form-card-footer button">
                            <CardButton action={submitForm}>Send Request</CardButton>
                        </div>
                    </CardFooter>
                </Card>
            </div>
        </div>
    )
}
