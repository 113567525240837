import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { GridActionButton, LinkButton } from "../../../components/buttons/Buttons";
import { GridContent, GridHeader, GridLoader, GridPaging, initGridState, setGridSelect, useGetGridData } from "../../../components/grid/Grid";
import { InputField } from "../../../components/inputs/Inputs";
import { PracticeCombo } from "../../combos/PracticeCombo";
import { ApplicationContext } from "../../contexts/AppContext";
import { useMousePosition } from "../../hooks/UseMousePosition";
import { DateFilter } from "./DateFilter";
import { PayForFilter } from "./PayForFilter";
import { PayMethodFilter } from "./PayMethodFilter";
import { PaySourceFilter } from "./PaySourceFilter";
import { AccountIdFilter } from "./AccountIdFilter";
import { SourceNameFilter } from "./SourceNameFilter";
import { ReferenceFilter } from "./ReferenceFilter";
import { AmountFilter } from "./AmountFilter";
import { LedgerForm } from "./LedgerForm";
import { download_file, friendlyDate } from "../../Toolbox";
import { useRestApi } from "../../hooks/UseRestApi"
import { Card, CardHeader, CardToolBar, CardToolBarTitle } from "../../../components/cards/Cards";

export const LedgerGrid = () => {
    let mousePos = useMousePosition()
    const moneyFormat = new Intl.NumberFormat('en-US', { style: "currency", currency: "USD" });
    const [applicationState, setApplicationState] = useContext(ApplicationContext);
    const [gridState, setGridState] = useState(initGridState("ledger-grid", 50));
    const gridData = useRestApi(gridState.url);
    const [filterEditor, setFilterEditor] = useState({ editor: "", value: "", mouseLeft: 0, mouseTop: 0 });
    const [editor, setEditor] = useState({ open: false, record: {}, index: -1 })
    const [printStatus, setPrintStatus] = useState(false)
    const [toggle, setToggle] = useState({
        practiceId: "",
        notesOpen: [],
        printStatus: false
    })

    const [filters, setFilters] = useState({
        startDate: new Date().toJSON().split('T')[0],
        endDate: new Date().toJSON().split('T')[0],
        payFor: "",
        paySource: "",
        payMethod: "",
        sourceName: "",
        account: "",
        reference: "",
        startAmount: 0.00,
        endAmount: 0.00
    })

    const gridColumns = [{
        columnid: "notes",
        width: "2%",
        type: 'action',
        render: (ndx) => {
            let hasNote = toggle.notesOpen.findIndex(r => r === gridData.data[ndx].id)
            return (<>
                {gridData.data[ndx].notes
                    ? <div className="inner pointer" style={{ textAlign: "center" }} title="Notes Available" data-id={gridData.data[ndx].id} onClick={toggleNotes}>
                        <FontAwesomeIcon icon={hasNote > -1 ? "fa-minus" : "fa-plus"} color="#497ca1" style={{ pointerEvents: "none" }} />
                    </div>
                    : <></>
                }
            </>)
        }
    }, {
        columnid: "ledgerdate",
        width: "6%",
        style: { pointerEvents: "none" },
        cellStyle: { pointerEvents: "none" },
        render: (ndx) => friendlyDate(gridData.data[ndx].ledgerdate)
    }, {
        columnid: "payfor",
        width: "10%",
        style: { pointerEvents: "none" },
        cellStyle: { pointerEvents: "none" },
        render: (ndx) => gridData.data[ndx].payfor.value
    }, {
        columnid: "paysource",
        width: "9%",
        style: { pointerEvents: "none" },
        cellStyle: { pointerEvents: "none" },
        render: (ndx) => gridData.data[ndx].paysource.value
    }, {
        columnid: "sourcename",
        width: "30%",
        style: { pointerEvents: "none" },
        cellStyle: { pointerEvents: "none" },
        render: (ndx) => gridData.data[ndx].sourcename
    }, {
        columnid: "accountid",
        width: "10%",
        style: { pointerEvents: "none" },
        cellStyle: { pointerEvents: "none" },
        render: (ndx) => gridData.data[ndx].account
    }, {
        columnid: "paymethod",
        width: "10%",
        style: { pointerEvents: "none" },
        cellStyle: { pointerEvents: "none" },
        render: (ndx) => gridData.data[ndx].paymethod.value
    }, {
        columnid: "reference",
        width: "10%",
        style: { pointerEvents: "none" },
        cellStyle: { pointerEvents: "none" },
        render: (ndx) => gridData.data[ndx].reference
    }, {
        columnid: "amount",
        width: "9%",
        style: { pointerEvents: "none" },
        cellStyle: { pointerEvents: "none", textAlign: "right" },
        render: (ndx) => moneyFormat.format(gridData.data[ndx].amount)
    }, {
        columnid: "actions",
        header: "",
        type: "action",
        width: "4%",
        style: { pointerEvents: "none" },
        cellStyle: { pointerEvents: "all" },
        render: (ndx) => (
            <div className="inner" style={{ display: "flex" }}>
                {!applicationState.master
                    ? <div style={{ flex: 1, textAlign: "center", cursor: "pointer" }} data-id={gridData.data[ndx].id} onClick={callEditor} title="Edit This Payment">
                        <FontAwesomeIcon icon="fa-pencil" color="#e7b05e" style={{ pointerEvents: "none" }} />
                    </div>
                    : <></>
                }
                {gridData.data[ndx].paysource.allowreceipt
                    ? <div style={{ flex: 1, textAlign: "center", cursor: "pointer" }} data-id={gridData.data[ndx].id} onClick={printReceipt} title={gridData.data[ndx].allowreceipt ? "Print Receipt" : ""}>
                        <FontAwesomeIcon icon="fa-receipt" color="#0c5081" style={{ pointerEvents: "none" }} />
                    </div>
                    : <div style={{ flex: 1, textAlign: "center", cursor: "pointer" }}></div>
                }
            </div>
        )
    }]

    const buildHeaders = () => {
        let today = new Date().toJSON().split('T')[0]
        const headers = [
            { id: "edit", width: "2%", text: "", allowFilter: false, filtered: false },
            { id: "ledgerdate", width: "6%", allowFilter: true, text: "Date", filtered: (filters.startDate !== filters.endDate) || (filters.startDate !== today) },
            { id: "payfor", width: "10%", allowFilter: true, text: "Payment For", filtered: filters.payFor ? true : false },
            { id: "paysource", width: "9%", allowFilter: true, text: "Source", filtered: filters.paySource ? true : false },
            { id: "sourcename", width: "30%", allowFilter: true, text: "Payer Name", filtered: filters.sourceName ? true : false },
            { id: "account", width: "10%", allowFilter: true, text: "Account ID", filtered: filters.account ? true : false },
            { id: "paymethod", width: "10%", allowFilter: true, text: "Payment Method", filtered: filters.payMethod ? true : false },
            { id: "reference", width: "10%", allowFilter: true, text: "Reference", filtered: filters.reference ? true : false },
            { id: "amount", width: "9%", allowFilter: true, text: "Amount", filtered: (filters.startAmount !== 0 || filters.endAmount !== 0) },
            { id: "edit", width: "4%", allowFilter: false, text: "", filtered: false }
        ]
        return headers.map((r, ndx) => {
            return (
                <div className="grid-action-cell pointer" style={{ width: r.width }} key={ndx}>
                    <div className="ledger-custom-cell-left">{headers[ndx].text}</div>
                    {r.allowFilter
                        ? <div className="ledger-custom-cell-right" onClick={() => callFilterEdit(r.id)}>
                            <FontAwesomeIcon style={{ pointerEvents: "none" }} icon="fa-filter" color={r.filtered ? "green" : "#497ca1"} />
                        </div>
                        : <></>}
                </div>
            )
        })
    }

    const buildRows = () => {
        if (typeof (gridData.data) === "object" && gridData.data.length) {
            return gridData.data.map((r, rndx) => {
                let rstyle = r.rowStyle || {}
                let noteOpen = toggle.notesOpen.findIndex(q => q === r.id)
                return (<React.Fragment key={rndx}>
                    <div className={`grid-row${gridState.selected === r.id ? " selected" : ""}`} style={rstyle} data-id={r.id} onClick={setRowSelected}>
                        {gridColumns.map((g, gndx) => {
                            let cstyle = g.cellStyle || {}
                            let newClass = g.type ? `grid-${g.type}-cell` : `grid-cell`
                            if (g.width) cstyle = Object.assign(cstyle, { width: g.width })
                            if (g.flex) cstyle = Object.assign(cstyle, { flex: g.flex })
                            return (<div className={newClass} key={`${rndx}-${gndx}`} style={cstyle}>{gridColumns[gndx].render(rndx)}</div>)
                        })}
                    </div>
                    <div id={`noterow-${r.id}`} className="ledger-note-row">{r.notes}</div>
                </React.Fragment>)
            })
        }
    }

    const setGridPage = (val) => {
        setGridState(ps => ({ ...ps, page: val, loader: !gridState.loader }))
    }

    const setRowSelected = ({ target }) => {
        setGridState(ps => ({ ...ps, selected: target.getAttribute("data-id") }))
    }

    const setPractice = (id, val) => {
        setToggle(ps => ({ ...ps, practiceId: val }))
    }

    const setGridFilter = (key, value) => {
        if (key === "ledgerdate") setFilters(ps => ({ ...ps, startDate: value.startDate, endDate: value.endDate }))
        if (key === "amount") setFilters(ps => ({ ...ps, startAmount: value.startAmount, endAmount: value.endAmount }))
        if (key === "payfor") setFilters(ps => ({ ...ps, payFor: value[0] }))
        if (key === "paymethod") setFilters(ps => ({ ...ps, payMethod: value[0] }))
        if (key === "paysource") setFilters(ps => ({ ...ps, paySource: value[0] }))
        if (key === "sourcename") setFilters(ps => ({ ...ps, sourceName: value }))
        if (key === "account") setFilters(ps => ({ ...ps, account: value }))
        if (key === "reference") setFilters(ps => ({ ...ps, reference: value }))
        setGridState(ps => ({ ...ps, loader: !gridState.loader, page: 1 }))
        setFilterEditor({ editor: "", mouseLeft: 0, mouseTop: 0 })
    }

    const resetFilters = () => {
        setFilters({
            startDate: new Date().toJSON().split('T')[0],
            endDate: new Date().toJSON().split('T')[0],
            payFor: "",
            paySource: "",
            payMethod: "",
            sourceName: "",
            account: "",
            reference: "",
            startAmount: 0.00,
            endAmount: 0.00
        })
        document.getElementById('ledger-date').value = new Date().toJSON().split('T')[0]
        setGridState(ps => ({ ...ps, loader: !gridState.loader, page: 1 }))
    }

    const toggleNotes = ({ target }) => {
        let noteList = [...toggle.notesOpen]
        let id = target.getAttribute("data-id")
        let ndx = noteList.findIndex(r => r === id)
        let noteRow = `noterow-${id}`
        if (ndx > -1) {
            noteList.splice(ndx, 1)
            document.getElementById(noteRow).classList.remove("open")
        } else {
            noteList.push(id)
            document.getElementById(noteRow).classList.add("open")
        }
        setToggle(ps => ({ ...ps, notesOpen: [...noteList] }))
    }

    const callEditor = ({ target }) => {
        let id = target.getAttribute("data-id")
        let ndx = gridData.data.findIndex(r => r.id === id)
        setEditor({ open: true, record: ndx > -1 ? gridData.data[ndx] : {} })
    }

    const editorCallBack = (resp) => {
        setEditor({ open: false, record: {}, index: -1 })
        let selected = resp.status === "success" ? resp.data.id : ""
        setGridState(ps => ({ ...ps, loader: !gridState.loader, selected: selected }))
    }

    const printReceipt = async ({ target }) => {
        let newUrl = `ledger/receipt?id=${target.getAttribute('data-id')}`
        await download_file(newUrl, "Ledger_Receipt.pdf", "pdf")
    }

    const printGridData = async () => {
        setPrintStatus(true)
        let newUrl = `ledger/print${buildLedgerUrl()}`
        await download_file(newUrl, "Ledger_Report.pdf", "pdf")
        setPrintStatus(false)
    }

    const callFilterEdit = (editorid) => {
        setFilterEditor({ editor: editorid, mouseLeft: mousePos.x, mouseTop: mousePos.y })
    }

    const filtersOn = () => {
        if ((filters.startDate !== filters.endDate) || (filters.startDate !== new Date().toJSON().split('T')[0])) return true;
        if ((filters.startAmount !== 0 || filters.endAmount !== 0)) return true;
        if (filters.payFor || filters.payMethod || filters.paySource || filters.sourceName || filters.account || filters.reference) return true;
        return false;
    }

    const buildLedgerUrl = () => {
        let parent = (applicationState.master) ? toggle.practiceId : applicationState.practiceid;
        let urlparams = `?parent=${parent}&datestart=${filters.startDate}&dateend=${filters.endDate}`
        if (filters.payMethod) urlparams += `&paymethod=${filters.payMethod}`
        if (filters.paySource) urlparams += `&paysource=${filters.paySource}`
        if (filters.payFor) urlparams += `&payfor=${filters.payFor}`
        if (filters.sourceName) urlparams += `&sourcename=${filters.sourceName}`
        if (filters.account) urlparams += `&account=${filters.account}`
        if (filters.reference) urlparams += `&reference=${filters.reference}`
        if (filters.startAmount) urlparams += `&amountstart=${filters.startAmount}`
        if (filters.endAmount) urlparams += `&amountend=${filters.endAmount}`
        urlparams += `&page=${gridState.page}&limit=${gridState.limit}&loader=${gridState.loader}`
        return (urlparams)
    }

    useEffect(() => {
        let urlParams = `ledger${buildLedgerUrl()}`
        setGridState(ps => ({ ...ps, url: urlParams }))
    }, [gridState.loader])

    return (
        <div className="ledger-wrapper">
            <Card style={{ width: "100%", height: "100% " }} nomodal>
                <CardHeader>Payment Ledger</CardHeader>
                <CardToolBar>
                    <CardToolBarTitle>Practice Payment Ledger</CardToolBarTitle>
                    {applicationState.master==1 && <>
                        <div style={{ width: "66px" }}>Practice:</div>
                        <div style={{ width: "300px", marginRight: "50px" }}><PracticeCombo dispatch={setPractice} /></div>
                    </>}
                    <div style={{ width: "95px" }}>Ledger Date:</div>
                    <div style={{ width: "120px", marginRight: "50px" }}>
                        <InputField
                            id="ledger-date"
                            hideError
                            inputAttrib={{
                                defaultValue: filters.startDate,
                                type: "date",
                                onKeyDown: (e) => e.preventDefault(),
                                onChange: (e) => setGridFilter("ledgerdate", { startDate: e.target.value, endDate: e.target.value })
                            }} />
                    </div>
                    <GridActionButton action={printGridData} style={{ marginRight: "10px", width: "148px" }} disable={printStatus}>
                        {!printStatus
                            ? <>
                                <FontAwesomeIcon icon="print" style={{ marginRight: "5px" }} />
                                Print Ledger Report
                            </>
                            : <>
                                <FontAwesomeIcon icon="spinner" style={{ marginRight: "5px" }} />
                                Printing
                            </>
                        }
                    </GridActionButton>

                    <GridActionButton action={resetFilters} style={{ marginRight: "10px" }} disable={!filtersOn()}>
                        <FontAwesomeIcon icon="filter-circle-xmark" style={{ marginRight: "5px" }} />
                        Clear Ledger Filters
                    </GridActionButton>
                    {!applicationState.master
                        ? <GridActionButton action={callEditor}>
                            <FontAwesomeIcon icon="plus" style={{ marginRight: "5px" }} />
                            New Ledger Entry
                        </GridActionButton>
                        : <></>
                    }
                </CardToolBar>
                <GridContent busy={gridData.busy}>
                    <div className="grid-row-header">{buildHeaders()}</div>
                    {buildRows()}
                </GridContent>
                {filterEditor.editor === "ledgerdate" && <DateFilter {...filters} {...filterEditor} dispatch={setGridFilter} />}
                {filterEditor.editor === "payfor" && <PayForFilter parent={(applicationState.master) ? toggle.practiceId : applicationState.practiceid} {...filters} {...filterEditor} dispatch={setGridFilter} />}
                {filterEditor.editor === "paymethod" && <PayMethodFilter parent={(applicationState.master) ? toggle.practiceId : applicationState.practiceid} {...filters} {...filterEditor} dispatch={setGridFilter} />}
                {filterEditor.editor === "paysource" && <PaySourceFilter parent={(applicationState.master) ? toggle.practiceId : applicationState.practiceid} {...filters} {...filterEditor} dispatch={setGridFilter} />}
                {filterEditor.editor === "account" && <AccountIdFilter {...filters} {...filterEditor} dispatch={setGridFilter} />}
                {filterEditor.editor === "sourcename" && <SourceNameFilter {...filters} {...filterEditor} dispatch={setGridFilter} />}
                {filterEditor.editor === "reference" && <ReferenceFilter {...filters} {...filterEditor} dispatch={setGridFilter} />}
                {filterEditor.editor === "amount" && <AmountFilter {...filters} {...filterEditor} dispatch={setGridFilter} />}
                {editor.open && <LedgerForm
                    parent={(applicationState.master) ? toggle.practiceId : applicationState.practiceid}
                    record={editor.record}
                    defaults={{ ledgerDate: filters.startDate }}
                    callback={editorCallBack}
                />}
                <div className="ledger-total-container">
                    <div style={{ width: "86.5%", textAlign: "right", float: "left" }}>Ledger Totals:</div>
                    <div style={{ width: "8.7%", float: "left", textAlign: "right" }}>{moneyFormat.format(gridData.total)}</div>
                    <div style={{ width: "4%", float: "left", borderRight: "none" }}></div>
                </div>
                <GridPaging page={gridState.page} limit={gridState.limit} count={gridData.count} dispatch={setGridPage} />
            </Card>
        </div>
    )
}
