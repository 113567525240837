
//action
//style

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const CardButton = (props) => {    
    return <button className={`card-button${props.small ? " small" : ""}`} {...props.btnAttrib} style={props.style || {}} onClick={props.action}>{props.children}</button>
}

export const LinkButton = (props) => {
    return <span className = "link-button" style={props.style || {}} onClick={props.action}>{props.children}</span>

}

export const GridNavButton = (props) => {    
    return <div className = {`grid-nav-button${props.disable ?" disabled" : "" }`} style={props.style || {}} onClick={props.action}>{props.children}</div>    
}

export const NavButton = (props) =>{    
    return( 
        <button className={`nav-button ${props.selected ? " selected" : ""}`} style={props.style || {}} onClick={props.action}>
            <div className="nav-button-content">
                <div className="nav-button-content-left"><FontAwesomeIcon icon={props.icon} /></div>
                <div className="nav-button-content-right">{props.children}</div>
            </div>
        </button>
    )
}

export const GridActionButton = (props) => {    
    return <button className={`grid-action-button`} disabled={props.disable} {...props.btnAttrib} style={props.style || {}} onClick={props.action}>{props.children}</button>
}