import { useContext, useEffect, useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { buildColHeader, buildRows, GridContent, GridHeader, GridPaging, initGridState, setGridSelect, useGetGridData } from "../../../components/grid/Grid"
import { faCirclePlus, faPencil } from "@fortawesome/free-solid-svg-icons";
import { GridActionButton, LinkButton } from "../../../components/buttons/Buttons";

import { ApplicationContext } from '../../contexts/AppContext';
import { LedgerTypeForm } from "./LedgerTypeForm";
import { CardToolBar, CardToolBarTitle } from "../../../components/cards/Cards";

export const LedTypeGrid = ({ type }) => {
    const [gridState, setGridState] = useState(initGridState("users-grid"))
    const [editor, setEditor] = useState({ open: false, record: {}, index: -1 })
    const gridData = useGetGridData(gridState.id, gridState.url)
    const [applicationState, setApplicationState] = useContext(ApplicationContext)
    const ledgertype = ["Payments For", "Payment Method", "Payment Source"]
    const gridColumns = [{
        columnId: "isdefault",
        hdrText: "Default",
        width: "12%",
        cellStyle: { textAlign: "center", fontSize: "1.05rem", paddingTop: "1px" },
        render: (ndx) => gridData.data[ndx].isdefault ? <FontAwesomeIcon icon="check" color="green" style={{ pointerEvents: "none", }} /> : <></>

    }, {
        columnId: "issystem",
        hdrText: "System",
        width: "12%",
        cellStyle: { textAlign: "center", fontSize: "1.05rem", paddingTop: "1px" },
        render: (ndx) => gridData.data[ndx].issystem ? <FontAwesomeIcon icon="check" color="navy" style={{ pointerEvents: "none" }} /> : <></>
    }, {
        columnId: "description",
        hdrText: "",
        width: "72%",
        render: (ndx) => gridData.data[ndx].description
    }, {
        columnId: "actions",
        hdrTextheader: "",
        width: "4%",
        type: "action",
        cellStyle: { textAlign: "center" },
        render: (ndx) => {
            if (!gridData.data[ndx].issystem) {
                return (
                    <div className="inner pointer" data-id={gridData.data[ndx].id} onClick={callEditor}>
                        <FontAwesomeIcon icon={faPencil} color="#e7b05e" style={{ pointerEvents: "none" }} />
                    </div>
                )
            }
        }
    }]

    const callEditor = (e) => {
        let id = e.target.getAttribute("data-id")
        let ndx = gridData.data.findIndex(r => r.id === id)
        setEditor({ open: true, record: ndx > -1 ? gridData.data[ndx] : {}, index: ndx })
    }

    const editorCallBack = (resp) => {
        setEditor({ open: false, record: {}, index: -1 })
        let selected = resp.status === "success" ? resp.data.id : ""
        setGridState(ps => ({ ...ps, loader: !gridState.loader, selected: selected }))
    }

    const updatePage = (page) => setGridState(ps => ({ ...ps, page: page, loader: !gridState.loader }))

    const updateSelected = (e) => setGridState(ps => ({ ...ps, selected: e.target.getAttribute("data-id") }))

    useEffect(() => {
        let selected = setGridSelect("id", gridData.data, gridState.selected, editor.index)
        setGridState(ps => ({ ...ps, busy: false, selected: selected }))
    }, [gridData])

    useEffect(() => {
        let newUrl = `ledgertype?ledgertype=${type}&parent=${applicationState.practiceid}&page=${gridState.page}&limit=${gridState.limit}&loader=${gridState.loader}`
        setGridState(ps => ({ ...ps, url: newUrl, busy: true }))
    }, [applicationState.practiceid,gridState.loader])

    return (<>
        <CardToolBar>
            <CardToolBarTitle>{ledgertype[type]}</CardToolBarTitle>
            <div style={{ flex: 1, textAlign: "right" }}>
                <GridActionButton action={callEditor}>
                    <FontAwesomeIcon icon="plus" style={{ marginRight: "5px" }} />
                    {`New ${ledgertype[type]}`}
                </GridActionButton>
            </div>
        </CardToolBar>
        <GridContent busy={gridData.busy}>            
            {buildColHeader(gridColumns)}
            {buildRows(gridColumns, gridData.data, gridState.selected, updateSelected)}
        </GridContent>
        <GridPaging page={gridState.page} limit={gridState.limit} count={gridData.count} dispatch={updatePage} />
        {/* <div className="card-container" style={{ width: "100%", height: "100%" }}>
            <GridHeader title={ledgertype[type]}>
                <LinkButton style={{ color: "#e7b05e", fontWeight: 400 }} action={callEditor}>
                    <FontAwesomeIcon icon={faCirclePlus} style={{ marginRight: "5px" }} />{`New ${ledgertype[type]}`}
                </LinkButton>
            </GridHeader>
            <GridContent busy={gridState.busy}>
                {buildColHeader(gridColumns)}
                {buildRows(gridColumns, gridData.data, gridState.selected, updateSelected)}
            </GridContent>
            <GridPaging page={gridState.page} limit={gridState.limit} count={gridData.count} dispatch={updatePage} />
        </div> */}
        {editor.open && <LedgerTypeForm type={type} parent={applicationState.practiceid} record={editor.record} callback={editorCallBack} />}
    </>)
}