import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from '@fortawesome/free-regular-svg-icons'
import { useContext, useEffect, useState } from "react"
import { CardToolBar, CardToolBarTitle } from "../../../components/cards/Cards";
import { GridContent, GridPaging, initGridState, setGridSelect, useGetGridData, GridCell } from "../../../components/grid/Grid"
import { InputSelect } from "../../../components/inputs/Inputs";
import { MsgContext } from "../../contexts/MsgContext";
import { StatusContext } from "../../contexts/StatusContext";
import { download_file, getApi } from "../../Toolbox";
import { MessageForm } from "./MessageForm";

export const MessageGrid = ({ reset }) => {
    const { messages, addMessages, selected, setSelected, folders } = useContext(MsgContext)
    const [statusState, statusDispatch] = useContext(StatusContext)
    const [gridState, setGridState] = useState(initGridState("users-grid", 10))
    const gridData = useGetGridData(gridState.id, gridState.url)
    const [editor, setEditor] = useState({ open: false, record: {}, index: -1, type: 0 })
    const [sort, setSort] = useState(["1"])
    const [folderPos, setFolderPos] = useState()

    const sortArray = [
        { id: "1", value: "Message Date -  Most Recent First" },
        { id: "2", value: " Sender First, Last - (Z- A)" },
        { id: "3", value: " Sender Last, First - (Z -A)" },
        { id: "4", value: " Message Subject  - (Z-A )" },
        { id: "5", value: "Message Date - Oldest First" },
        { id: "6", value: "Sender First, Last - (A-Z )" },
        { id: "7", value: "Sender Last, First - (A-Z )" },
        { id: "8", value: "Message Subject - (A-Z )" },
    ]
    const gridColumns = [{
        columnid: "filerow",
        width: "100%",
        render: (ndx) => {
            let sndx = selected.findIndex(r => r === messages.data[ndx].id)
            return (<>
                <div className="mail-row">
                    <div className="mail-row-left">
                        {sndx === -1
                            ? <FontAwesomeIcon icon={faSquare} color="#0c5081" />
                            : <FontAwesomeIcon icon="check-square" color="#0c5081" />
                        }
                    </div>
                    <div className="mail-row-mid">
                        <div className="mail-subject">{messages.data[ndx].subject}</div>
                        <div className="mail-sender"> From {messages.data[ndx].from.name}</div>
                    </div>
                    <div className="mail-row-right">
                        <div className="mail-date">{messages.data[ndx].msgdate}</div>
                        <div className="mail-icons">
                            <div style={{minHeight:"17px"}}></div>
                            {messages.data[ndx].repliedto
                                ? <div style={{ width: "20px" }}>
                                    {messages.data[ndx].repliedto ? <FontAwesomeIcon icon="fa-reply-all" color="#0c5081" /> : <></>}
                                </div>
                                : <></>
                            }
                            {messages.data[ndx].flagged
                                ? <div style={{ width: "20px" }}>
                                    {messages.data[ndx].flagged ? <FontAwesomeIcon icon="fa-flag" color="#ff4d4d" /> : <></>}
                                </div>
                                : <></>
                            }
                        </div>
                    </div>
                </div>
            </>)
        }
    }]

    const buildMessageRows = () => {
        return messages.data.map((r, rndx) => {
            return (
                <div
                    id={r.id}
                    className={`grid-row${(selected.findIndex(q => q === r.id)) > -1 ? " selected" : ""}`}
                    onMouseUp={updateSelected}
                    key={`r${rndx}`}
                    style={{ height: "54px" }}
                    draggable={folders.position == 1 ? false : true}
                    onDragStart={handleDragStart}
                >
                    {gridColumns.map((c, cndx) => {
                        let cstyle = c.cellStyle || {}
                        if (c.width) {
                            cstyle = Object.assign(cstyle, { width: c.width })
                            return (<GridCell style={cstyle || {}} key={`${rndx}-${cndx}`}>{c.render(rndx)}</GridCell>)
                        }
                    })}
                </div>
            )
        })
    }

    const updatePage = (page) => {
        setGridState(ps => ({ ...ps, page: page, loader: !gridState.loader }))
    }

    const updateSelected = (e) => {
        let list = [...selected]
        let id = e.target.id
        let ndx = selected.findIndex(r => r === id)
        if (e.ctrlKey) {
            ndx > -1 ? list.splice(ndx, 1) : list.push(id)
        } else if (e.shiftKey) {
            let startNdx = 0;
            let endNdx = messages.data.findIndex(r => r.id === id);
            if (endNdx < startNdx) [startNdx, endNdx] = [endNdx - 1, startNdx];
            for (let i = startNdx; i <= endNdx; i++) list.push(messages.data[i].id)
        } else {
            const id = e.target.id
            list = [id]
            setGridState(ps => ({ ...ps, selected: id }))
        }
        setSelected([...list])
    }

    const callEditor = (actionType) => {
        let ndx = messages.data.findIndex(r => r.id === selected[0])
        setEditor({ open: true, record: ndx > -1 ? messages.data[ndx] : {}, index: ndx, type: actionType })
    }

    const editorCallBack = (resp) => {
        setEditor({ open: false, record: {}, index: -1 })
        if (resp.status === "success") {
            setSelected([resp.data.id])
            statusDispatch({ level: "success", message: "Your Message Has Been Sent", timeout: 2000 })
            setGridState(ps => ({ ...ps, loader: !gridData.loader }))
            setMessageFlag("reply")
        }
    }

    const setButtonClass = (btnType) => {
        let newClass = "message-action-icon-container"
        if (selected.length != 1 || (folders.position === 1 && (btnType === 1 || btnType === 2))) newClass += " disabled"
        return newClass
    }

    const setButtonAction = (btnType) => {
        let enabled = true
        if (selected.length != 1 || (folders.position === 1 && (btnType === 1 || btnType === 2))) enabled = false
        return enabled
    }

    const setMessageFlag = async (flag) => {
        let rec = messages.data.find(r => r.id === selected[0])
        let url = `messages/${flag}?id=${rec.id}`
        await getApi(url)
        setGridState(ps => ({ ...ps, loader: !gridState.loader }))
    }

    const setSearch = () => setGridState(ps => ({ ...ps, loader: !gridState.loader }))

    const handleDragStart = (e) => {
        let list = [...selected]
        let id = e.target.id;
        if (list.findIndex(r => r == id) === -1) {
            list = [id];
        }
        let ghost = document.createElement("span")
        ghost.id = "drag-ghost"
        if (list.length === 1) {
            let r = messages.data.find(r => r.id == id)
            ghost.innerText =
                `${r.from.name}
             ${r.msgdate}
             ${r.subject}
            `;
        } else {
            ghost.innerText =
                `Multiple
             Messages`;
        }
        ghost.classList.add("mail-drag-ghost");
        document.getElementById('mail-container-title').appendChild(ghost);
        e.dataTransfer.setDragImage(ghost, 4, 4)
        e.dataTransfer.setData("dragList", list)
        setGridState(ps => ({ ...ps, loader: !gridState.loader }))
    }

    const printMessage = async () => {
        let newUrl = `messages/print?id=${selected[0]}`
        await download_file(newUrl, "Mail_Message.pdf", "pdf")
    }

    useEffect(() => {
        let id = setGridSelect("id", gridData.data, messages.selected, editor.index)
        setSelected(id ? [id]: [])
        setGridState(ps => ({ ...ps, selected: id }))
        addMessages(!gridData.data.length ? [] : gridData.data)
    }, [gridData])

    useEffect(() => {
        setGridState(ps => ({ ...ps, url: newUrl, busy: true }))
        let newUrl = `messages?parent=${folders.selected}&page=${gridState.page}&limit=${gridState.limit}&reset=${reset} loader=${gridState.loader}&sort=${sort}`
        if (document.getElementById("message-search")) {
            let search = document.getElementById("message-search").value
            if (search) newUrl = `${newUrl}&search=${search}`
        }
    }, [gridState.loader, folders.selected, sort, reset])

    return (
        <div id="mail-container-title">
            <CardToolBar>
                <a id="downloader" style={{ width: "0px", height: "0px" }}></a>
                <CardToolBarTitle style={{ marginRight: "20px" }}>Messages</CardToolBarTitle>
                <div style={{ flex: "1 1 auto", display: "flex", alignItems: "center", marginRight: "20px" }}>
                    <div style={{ width: "40px", paddingTop: "2px" }}>Sort:</div>
                    <div style={{ flex: 1 }}>
                        <InputSelect id="sort-select" items={sortArray} selected={sort} setSelected={(id, val) => setSort(val)} hideError />
                    </div>
                </div>
                <div style={{ flex: "1 1 auto", display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
                    <div className={setButtonClass(1)} onClick={() => setButtonAction(1) && callEditor(1)} title="Reply To Sender">
                        <FontAwesomeIcon icon="fa-reply" style={{ pointerEvents: "none" }} />
                    </div>
                    <div className={setButtonClass(2)} onClick={() => setButtonAction(2) && callEditor(2)} title="Reply To All">
                        <FontAwesomeIcon icon="fa-reply-all" style={{ pointerEvents: "none" }} />
                    </div>
                    <div className={setButtonClass(3)} onClick={() => setButtonAction(3) && callEditor(3)} title="Forward">
                        <FontAwesomeIcon icon="fa-share" style={{ pointerEvents: "none" }} />
                    </div>
                    <div className={setButtonClass(4)} onClick={() => setButtonAction(4) && setMessageFlag("flag")} title="Flag This Message">
                        <FontAwesomeIcon icon="fa-flag" style={{ pointerEvents: "none" }} />
                    </div>
                    <div className={setButtonClass(5)} onClick={() => setButtonAction(5) && printMessage()} title="Print This Message">
                        <FontAwesomeIcon icon="fa-print" style={{ pointerEvents: "none" }} />
                    </div>
                </div>
            </CardToolBar>
            <GridContent busy={gridData.busy}>
                {buildMessageRows()}
            </GridContent>
            <GridPaging page={gridState.page} limit={gridState.limit} count={gridData.count} dispatch={updatePage} />
            {editor.open && <MessageForm record={editor.record} msgtype={editor.type} callback={editorCallBack} />}
        </div>
    )
}