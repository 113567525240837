
import { useEffect, useState } from "react"
import { InputSelect } from "../../components/inputs/Inputs"
import { getApi } from "../Toolbox"

export const UserCombo = ({ parent ,dispatch, label }) => {
    const [comboItems, setComboItems] = useState({ data: [], selected: [] })

    const dispatchSelected = (id, val) => {
        setComboItems(ps => ({ ...ps, selected: val }))
        dispatch(id, val)
    }

    useEffect(() => {        
        const loadData = async () => {
            let url = `users?parent=${parent}&combo=1&page=1&limit=1000`
            let resp = await getApi(url)
            resp.data.unshift({id:"",value:"No Private Recipient"});
            resp.status === "success" && setComboItems({ data: resp.data, selected: [resp.data[0].id] })            
            dispatch("user-combo", [resp.data[0].id])
        }
        loadData()
    }, [parent]);

    return (
        <InputSelect
            id="user-combo"
            label={label}
            items={comboItems.data}
            selected={comboItems.selected}
            setSelected={dispatchSelected}
            hideError
        />
    )
}