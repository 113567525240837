import { useEffect, useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { buildColHeader, buildRows, GridContent, GridPaging, initGridState, setGridSelect, useGetGridData } from "../../../components/grid/Grid"
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { PortalForm } from "./PortalForm";
import { CardToolBar, CardToolBarTitle } from "../../../components/cards/Cards";
import { GridActionButton } from "../../../components/buttons/Buttons";

export const PortalGrid = () => {
    const [gridState, setGridState] = useState(initGridState("practice-grid",40),)
    const gridData = useGetGridData(gridState.id, gridState.url)
    const [editor, setEditor] = useState({ open: false, record: {}, index: -1 })
    const gridColumns = [{
        columnId: "portalname",
        hdrText: "Portal Name",
        width: "40%",
        render: (ndx) => gridData.data[ndx].portalname
    }, {
        columnId: "weburl",
        hdrText: "Web URL",
        width: "40%",
        render: (ndx) => gridData.data[ndx].weburl
    }, {
        columnId: "portaltype",
        hdrText: "Portal Type",
        width: "16%",
        render: (ndx) => {
            const portalType = ["Insurance", "Hospital", "EMR / EHR", "Other"]
            return portalType[parseInt(gridData.data[ndx].portaltype)]
        }
    }, {
        columnId: "actions",
        hdrTextheader: "",
        width: "4%",
        type: "action",
        cellStyle: { textAlign: "center" },
        render: (ndx) => (
            <div className="inner pointer" data-id={gridData.data[ndx].id} onClick={callEditor}>
                <FontAwesomeIcon icon={faPencil} color="#e7b05e" style={{ pointerEvents: "none" }} />
            </div>
        )
    }]

    const callEditor = (e) => {
        let id = e.target.getAttribute("data-id")
        let ndx = gridData.data.findIndex(r => r.id === id)
        setEditor({ open: true, record: ndx > -1 ? gridData.data[ndx] : {}, index: ndx })
    }

    const editorCallBack = (resp) => {
        setEditor({ open: false, record: {}, index: -1 })
        let selected = resp.status === "success" ? resp.data.id : gridState.selected
        setGridState(ps => ({ ...ps, loader: !gridState.loader, selected: selected }))
    }

    const updatePage = (page) => {
        setGridState(ps => ({ ...ps, page: page, loader: !gridState.loader }))
    }

    const updateSelected = (e) => {
        setGridState(ps => ({ ...ps, selected: e.target.getAttribute("data-id") }))
    }

    useEffect(() => {
        let selected = setGridSelect("id", gridData.data, gridState.selected, editor.index)
        setGridState(ps => ({ ...ps, busy: false, selected: selected }))
    }, [gridData])

    useEffect(() => {
        let newUrl = `portals?page=${gridState.page}&limit=${gridState.limit}&reset=${gridState.loader}`
        setGridState(ps => ({ ...ps, url: newUrl, busy: true }))
    }, [gridState.loader])

    return (<>
        <CardToolBar>
            <CardToolBarTitle>Websites / Portals</CardToolBarTitle>
            <div style={{ flex: 1, textAlign: "right" }}>
                <GridActionButton action={callEditor}>
                    <FontAwesomeIcon icon="plus" style={{ marginRight: "5px" }} />
                    New Website/Portal
                </GridActionButton>
            </div>
        </CardToolBar>
        <GridContent busy={gridData.busy}>
            {buildColHeader(gridColumns)}
            {buildRows(gridColumns, gridData.data, gridState.selected, updateSelected)}
        </GridContent>
        <GridPaging page={gridState.page} limit={gridState.limit} count={gridData.count} dispatch={updatePage} />
        {editor.open && <PortalForm record={editor.record} callback={editorCallBack} />}
    </>)
}